import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';

import { ConnectorNames, connectorsByName } from '../utils/connector';
import Web3 from 'web3';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import { Notification } from 'components/Notification';

// import { useAppDispatch } from "hooks";
// import { clearConnectedWallet } from "redux/store/slices";

const handleError = (error: Error) => {
  if (error instanceof NoEthereumProviderError) {
    Notification(
      'error',
      <>
        Mobile: the browser within the MetaMask wallet.
        <br />
        Desktop: any MetaMask compatible browser, after you’ve installed the MetaMask extension.
      </>,
      'Please connect to Crypto My Biz via:'
    );
  } else if (error instanceof UnsupportedChainIdError) {
    Notification('error', '', "You're connected to an unsupported network.");
  } else if (error instanceof UserRejectedRequestErrorInjected) {
    Notification('error', '', 'Please authorize this website to access your Ethereum account.');
  } else if ((error as any).code === -32002) {
    Notification('error', '', 'Already processing ethereum request Accounts. Please accept the request.');
  } else {
    console.error(error.toString());
    Notification('error', '', 'An unknown error occurred. Check the console for more details.');
  }
};

export function activateInjectedProvider(providerName: 'MetaMask' | 'CoinBase') {
  const { ethereum } = window as any;

  if (!ethereum?.providers) {
    return undefined;
  }

  let provider;
  switch (providerName) {
    case 'CoinBase':
      provider = ethereum.providers.find(({ isCoinbaseWallet }: { isCoinbaseWallet: any }) => isCoinbaseWallet);
      break;
    case 'MetaMask':
      provider = ethereum.providers.find(({ isMetaMask }: { isMetaMask: any }) => isMetaMask);
      break;
  }

  if (provider) {
    ethereum.setSelectedProvider(provider);
  }
}

export const useWallet = () => {
  const { activate, connector, ...props } = useWeb3React<Web3>();
  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   const { ethereum } = window as any;

  //   if (ethereum) {
  //     (async () => {
  //       try {
  //         const web3 = new Web3(
  //           ethereum.currentProvider || (window as any).web3.currentProvider
  //         );
  //         const accounts: Array<String> = await web3.eth.getAccounts();
  //         // }
  //         await ethereum.enable();
  //           ethereum
  //             .request({ method: 'eth_accounts' })
  //             .then(() => console.log('àasf'))
  //             .catch(console.error);
  //       } catch (err) {
  //         // Handle Error
  //       }
  //     })();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const connect = async (type: keyof typeof ConnectorNames) => {
    try {
      if (type === ConnectorNames.Injected) {
        const { ethereum } = window as any;
        if (!ethereum) {
          return Notification(
            'error',
            <>
              Mobile: the browser within the MetaMask wallet.
              <br />
              Desktop: any MetaMask compatible browser, after you’ve installed the MetaMask extension.
            </>,
            'Please connect to Crypto My Biz via:'
          );
        }

        activateInjectedProvider('MetaMask');
      }

      return await activate(connectorsByName[type], undefined, true);
    } catch (err: any) {
      console.log('Connect wallet err', err);
      if (err instanceof UnsupportedChainIdError) {
        props.deactivate();
        // dispatch(clearConnectedWallet());
      }
      handleError(err);
      throw err;
    }
  };

  return { ...props, connector, connect };
};
