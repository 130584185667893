import { memo } from 'react';
import { FC } from 'react';
import { ButtonProps } from './button.props';
import { FillBtn } from './button.style';

export const FillButton: FC<ButtonProps> = ({ children, ...rest }) => {
  return <FillBtn {...rest}>{children}</FillBtn>;
};

export default memo(FillButton);
