import styled from 'styled-components';
import { Title as CTitle } from 'components';
import { Button, Menu as AMenu } from 'antd';

export const Title = styled(CTitle)`
  margin-bottom: 56px;
  text-align: center;
`;

export const ContractList = styled.div`
  padding-right: 10px;
`;

export const ContractLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuWrapper = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  gap: 30px;
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md}) {
    display:block;
  }
`;

export const ActionBtnWrap = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 40px;
`;

export const BtnWrap = styled(ActionBtnWrap)`
  margin-top: 10px;
`;

export const Menu = styled(AMenu)`
  padding: 0px;
  padding-right: 5px;
  gap: 8px;
  width: 300px;
  max-height: 706px;
  overflow-y: auto;
  overflow-x: hidden;
  background: transparent;
  .ant-menu-item-selected {
    background-color: #3878a4 !important;
    &::after {
      display: none;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md}) {
    height:160px;
    padding: 0px;
   margin: auto;
   width:250px;

  }
`;

export const MenuItem = styled(AMenu.Item)`
  padding: 40px;
  gap: 48px;
  width: 300px;
  height: 72px !important;
  background: linear-gradient(0deg, rgba(86, 86, 86, 0.25), rgba(86, 86, 86, 0.25)), rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 40px rgba(24, 35, 43, 0.1);
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #bdbdbd !important;
  &:hover {
    background: #3878a4;
  }
`;

export const ButtonOutline = styled(Button)`
  width: 50%;
  height: 72px;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #ffffff !important;
  border: 1px solid rgba(111, 191, 236, 0.3) !important;
  text-transform: inherit;
  border-radius: 0;
  &:hover {
    background: #355f73;
  }
`;

export const ExportCSVBtn = styled(ButtonOutline)`
  width: 100%;
  margin-top: 10px;
`;

export const CancelBtn = styled(ButtonOutline)`
  width: 40%;
  margin-top: 10px;
  margin-right: 10px;
`;

export const ExportBtn = styled(ButtonOutline)`
  width: 60%;
  margin-top: 10px;
`;
