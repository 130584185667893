export const FileAdd = () => {
  return (
    <svg width="51" height="64" viewBox="0 0 51 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49.5891 16.2922L34.4578 1.16094C34.0359 0.739063 33.4664 0.5 32.8687 0.5H3C1.75547 0.5 0.75 1.50547 0.75 2.75V61.25C0.75 62.4945 1.75547 63.5 3 63.5H48C49.2445 63.5 50.25 62.4945 50.25 61.25V17.8883C50.25 17.2906 50.0109 16.7141 49.5891 16.2922ZM45.0609 18.9219H31.8281V5.68906L45.0609 18.9219ZM45.1875 58.4375H5.8125V5.5625H27.0469V20.75C27.0469 21.5332 27.358 22.2844 27.9118 22.8382C28.4656 23.392 29.2168 23.7031 30 23.7031H45.1875V58.4375ZM27.75 29.1875C27.75 28.8781 27.4969 28.625 27.1875 28.625H23.8125C23.5031 28.625 23.25 28.8781 23.25 29.1875V36.7812H15.6562C15.3469 36.7812 15.0938 37.0344 15.0938 37.3438V40.7188C15.0938 41.0281 15.3469 41.2812 15.6562 41.2812H23.25V48.875C23.25 49.1844 23.5031 49.4375 23.8125 49.4375H27.1875C27.4969 49.4375 27.75 49.1844 27.75 48.875V41.2812H35.3438C35.6531 41.2812 35.9062 41.0281 35.9062 40.7188V37.3438C35.9062 37.0344 35.6531 36.7812 35.3438 36.7812H27.75V29.1875Z"
        fill="#2D9CDB"
      />
    </svg>
  );
};

export const FileDone = () => {
  return (
    <svg width="53" height="62" viewBox="0 0 53 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.875 16.9375V13.5625C38.875 13.2531 38.6219 13 38.3125 13H11.3125C11.0031 13 10.75 13.2531 10.75 13.5625V16.9375C10.75 17.2469 11.0031 17.5 11.3125 17.5H38.3125C38.6219 17.5 38.875 17.2469 38.875 16.9375ZM11.3125 23.125C11.0031 23.125 10.75 23.3781 10.75 23.6875V27.0625C10.75 27.3719 11.0031 27.625 11.3125 27.625H24.25C24.5594 27.625 24.8125 27.3719 24.8125 27.0625V23.6875C24.8125 23.3781 24.5594 23.125 24.25 23.125H11.3125ZM37.75 31.2812C29.3617 31.2812 22.5625 38.0805 22.5625 46.4688C22.5625 54.857 29.3617 61.6562 37.75 61.6562C46.1383 61.6562 52.9375 54.857 52.9375 46.4688C52.9375 38.0805 46.1383 31.2812 37.75 31.2812ZM45.3086 54.0273C43.2906 56.0453 40.6047 57.1562 37.75 57.1562C34.8953 57.1562 32.2094 56.0453 30.1914 54.0273C28.1734 52.0094 27.0625 49.3234 27.0625 46.4688C27.0625 43.6141 28.1734 40.9281 30.1914 38.9102C32.2094 36.8922 34.8953 35.7812 37.75 35.7812C40.6047 35.7812 43.2906 36.8922 45.3086 38.9102C47.3266 40.9281 48.4375 43.6141 48.4375 46.4688C48.4375 49.3234 47.3266 52.0094 45.3086 54.0273ZM44.0078 41.125H40.893C40.7102 41.125 40.5414 41.2094 40.4359 41.357L35.9711 47.5305L34.3469 45.2875C34.2948 45.215 34.2262 45.1562 34.1466 45.1158C34.0671 45.0754 33.979 45.0547 33.8898 45.0555H30.7891C30.332 45.0555 30.0648 45.5758 30.332 45.9484L35.5211 53.1273C35.7461 53.4367 36.2031 53.4367 36.4281 53.1273L44.4578 42.018C44.732 41.6453 44.4648 41.125 44.0078 41.125ZM21.4375 54.9062H5.125V5.40625H44.5V29.5938C44.5 29.9031 44.7531 30.1562 45.0625 30.1562H49C49.3094 30.1562 49.5625 29.9031 49.5625 29.5938V2.59375C49.5625 1.34922 48.557 0.34375 47.3125 0.34375H2.3125C1.06797 0.34375 0.0625 1.34922 0.0625 2.59375V57.7188C0.0625 58.9633 1.06797 59.9688 2.3125 59.9688H21.4375C21.7469 59.9688 22 59.7156 22 59.4062V55.4688C22 55.1594 21.7469 54.9062 21.4375 54.9062Z"
        fill="#2D9CDB"
      />
    </svg>
  );
};
