import { ChangeEvent, FC, memo, useState } from 'react';
import { phonesNumber } from './data';
import { PhoneNumberProps, PhoneType } from './phoneNumber.props';
import { PhoneInput, PhoneSelect } from './phoneNumber.style';

const PhoneNumberInput: FC<PhoneNumberProps> = ({ value = {}, onChange }) => {
  const [prefix, setPrefix] = useState('CA');
  const [phone, setPhone] = useState('');

  const triggerChange = (changedValue: Partial<PhoneType>) => {
    onChange?.({ prefix, phone, ...value, ...changedValue });
  };

  const onPrefixChange = (selectedVal: any) => {
    const currVal: string = selectedVal;
    const foundPrefix = phonesNumber.find((item) => (value.phone || phone).startsWith(item.dial_code));
    const phoneWithoutDialCode: string = (value.phone || phone).slice(foundPrefix?.dial_code.length);

    if (!('prefix' in value)) {
      setPrefix(currVal);
      setPhone(phoneWithoutDialCode);
    }
    triggerChange({ prefix: currVal, phone: phoneWithoutDialCode });
  };

  const onPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currVal = e.target.value;

    if (!('phone' in value)) {
      setPhone(currVal);
    }
    triggerChange({ phone: currVal });
  };

  const prefixSelector = (
    <PhoneSelect
      optionLabelProp="label"
      dropdownMatchSelectWidth={200}
      getPopupContainer={(node) => document.querySelector('.ant-select') || node}
      placeholder="flag"
      showSearch
      value={value.prefix || prefix}
      onChange={onPrefixChange}
      filterOption={(input, option) => {
        const parsedInput = parseInt(input);

        if (isNaN(parsedInput)) {
          return option?.key?.toString().includes(input);
        } else {
          return option?.dialcode.includes(input);
        }
      }}
    >
      {phonesNumber.map((item) => (
        <PhoneSelect.Option
          key={item.name}
          value={item.code}
          label={`${item.flag} (${item.dial_code})`}
          dialcode={item.dial_code}
        >
          <span>
            {item.flag} ({item.dial_code}) {item.name}
          </span>
        </PhoneSelect.Option>
      ))}
    </PhoneSelect>
  );

  return (
    <PhoneInput
      addonBefore={prefixSelector}
      value={value.phone || phone}
      onChange={onPhoneChange}
      placeholder={`(${phonesNumber.find((item) => item.code === (value.prefix || prefix))!.dial_code})`}
    />
  );
};

export default memo(PhoneNumberInput);
