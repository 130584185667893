import styled from 'styled-components';
import { Title as CTitle } from 'components';
import { Button } from 'antd';

export const Title = styled(CTitle)`
  margin-bottom: 56px;
`;

export const LoadmoreBtn = styled(Button)`
  margin-top: 10px;
  width: 50%;
  height: 72px;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #ffffff !important;
  border: 1px solid rgba(111, 191, 236, 0.3) !important;
  text-transform: inherit;
  border-radius: 0;
  &:hover {
    background: #355f73;
  }
`;

export const ContractsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
