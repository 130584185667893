import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const storageWallet = localStorage.getItem('wallet');
const storageWalletParsed = storageWallet ? JSON.parse(storageWallet) : null;

const storageToken = localStorage.getItem('token');
const storageTokenParsed = storageToken ? JSON.parse(storageToken) : null;

const storageAdmin = localStorage.getItem('isAdmin');
const storageAdminParsed = storageAdmin ? JSON.parse(storageAdmin) : false;

interface IAuth {
  wallet: string | null;
  access_token: string | null;
  isAdmin: boolean;
}

const initialState: IAuth = {
  wallet: storageWalletParsed,
  access_token: storageTokenParsed,
  isAdmin: storageAdminParsed,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthData: (state, action: PayloadAction<IAuth>) => {
      state.wallet = action.payload.wallet;
      state.access_token = action.payload.access_token;
      state.isAdmin = action.payload.isAdmin;
    },
  },
});

export const { setAuthData } = authSlice.actions;

export default authSlice.reducer;
