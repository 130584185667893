import { CreateBusiness } from 'types/business';
import instance from './common';

export const createBusinessProfile = (body: CreateBusiness) => {
  return instance.post('/users', body);
};

export const updateBusinessProfile = (id: string, body: CreateBusiness) => {
  return instance.put(`/users/${id}`, body);
};

export const getBusinessProfileInfo = (walletAddress: string) => {
  return instance.get(`/users/wallet-address/${walletAddress}`);
};
