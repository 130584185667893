import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContractData } from 'types/contract';

interface IContract {
  contract: ContractData | null;
  isUpdate: boolean;
}

const initialState: IContract = {
  contract: null,
  isUpdate: false,
};

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setContract: (state, action: PayloadAction<ContractData>) => {
      state.contract = action.payload;
    },
    clearContract: (state) => {
      state.contract = null;
      state.isUpdate = false;
    },
    indicateUpdateContract: (state) => {
      state.isUpdate = true;
    },
  },
});

export const { setContract, clearContract, indicateUpdateContract } = contractSlice.actions;
