import { ContractData, ContractSearch } from 'types/contract';
import instance from './common';

export const encryptContractData = (body: ContractData) => {
  return instance.post('/contracts/encrypt', body);
};

export const exportContract = (contract_ids: string[]) => {
  return instance.post('/contracts/export-csv', { contract_ids });
};

export const getContracts = ({
  name,
  contract,
  contract_date,
  short_description,
  wallet_address,
  page,
  is_expired,
  status,
}: ContractSearch) => {
  const defaultURL = `?name=${name}&contract=${contract}&short_description=${short_description}&wallet_address=${wallet_address}&page=${page.toString()}`;
  let urlWithStatus = '';

  if (is_expired && status) {
    urlWithStatus = defaultURL.concat(`&is_expired=${is_expired}&status=${status}`);
  }

  const hasDate = (urlWithStatus.length > 0 ? urlWithStatus : defaultURL).concat(`&contract_date=${contract_date}`);
  const params = new URLSearchParams(contract_date ? hasDate : defaultURL);
  return instance.get(`/contracts`, { params });
};

export const getContractById = (id: string) => {
  return instance.get(`/contracts/${id}`);
};

export const getContractsHandleByAdmin = (page = 1) => {
  const params = `?page=${page}`;

  return instance.get('/contracts/admin', { params });
};
