import { Footer } from 'antd/lib/layout/layout';
import styled from 'styled-components';

export const FooterLayout = styled(Footer)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #bdbdbd;
  bottom: 0;
  background: transparent;
  width: 100%;
`;

export const Socials = styled.div`
  svg {
    display: inline-block;
    position: relative;
    width: 28px;
    height: 28px;
    color: #bdbdbd;
    font-family: 'WA-theme-icons';
    font-size: 24px;
    line-height: 28px;
    vertical-align: top;
    margin: 15px 0 0 16px;
  }
`;
