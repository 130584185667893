import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IBusinessProfile {
  id?: string;
  businessName: string;
  businessAddress: string;
  businessEmail: string;
  prefix?: string;
  businessPhone: string;
  walletAddress: string;
}

const initialState: {
  business: IBusinessProfile | null;
} = {
  business: null,
};

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setBusinessProfile: (state, action: PayloadAction<IBusinessProfile | null>) => {
      state.business = action.payload;
    },
  },
});

export const { setBusinessProfile } = businessSlice.actions;
