import styled from 'styled-components';
import { Content } from 'antd/lib/layout/layout';
import background from 'assets/images/homepage-background.jpg';

export const ContentLayout = styled(Content)`
  height: calc(100vh - 118px);
  background: url(${background});
  mix-blend-mode: normal;
  backdrop-filter: blur(241px);
  overflow-y: auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;

export const BgMask = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
  z-index: -1;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
`;
