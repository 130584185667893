import { BaseComponent } from 'components/baseComponent';
import { FC, ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';
import { TitleProps } from './text.props';
import { NavBarText, H1, H2, SmallerDescription, TextForm, H3, H6 } from './text.style';

interface NavbarProps extends LinkProps {
  children?: ReactNode;
}

export const NavbarItem: FC<NavbarProps> = ({ children, ...rest }) => {
  return <NavBarText {...rest}>{children}</NavBarText>;
};

export const Title: FC<TitleProps> = ({ children, level, ...rest }) => {
  let RenderComponent: any;

  switch (level) {
    case 1:
      RenderComponent = H1;
      break;
    case 2:
      RenderComponent = H2;
      break;
    case 3:
      RenderComponent = H3;
      break;
    case 6:
      RenderComponent = H6;
      break;

    default:
      break;
  }

  return <RenderComponent {...rest}>{children}</RenderComponent>;
};

export const Description: FC<BaseComponent<HTMLSpanElement>> = ({ children, ...rest }) => {
  return <SmallerDescription {...rest}>{children}</SmallerDescription>;
};

export const Label: FC<BaseComponent<HTMLSpanElement>> = ({ children, ...rest }) => {
  return <TextForm {...rest}>{children}</TextForm>;
};
