export const CheckCircle = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9998 2.6665C8.64784 2.6665 2.6665 8.64784 2.6665 15.9998C2.6665 23.3518 8.64784 29.3332 15.9998 29.3332C23.3518 29.3332 29.3332 23.3518 29.3332 15.9998C29.3332 8.64784 23.3518 2.6665 15.9998 2.6665ZM15.9998 26.6665C10.1185 26.6665 5.33317 21.8812 5.33317 15.9998C5.33317 10.1185 10.1185 5.33317 15.9998 5.33317C21.8812 5.33317 26.6665 10.1185 26.6665 15.9998C26.6665 21.8812 21.8812 26.6665 15.9998 26.6665Z"
        fill="#78CB4A"
      />
      <path
        d="M13.3318 18.1158L10.2665 15.0558L8.38379 16.9438L13.3345 21.8838L22.2758 12.9425L20.3905 11.0571L13.3318 18.1158Z"
        fill="#78CB4A"
      />
    </svg>
  );
};
