import { FC, memo } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { routesEnum } from 'pages/Router';

const AdminRoute: FC<RouteProps> = (props) => {
  const { isAdmin } = useAppSelector((state) => state.auth);

  if (isAdmin) {
    return <Route {...props} />;
  }

  return <Redirect to={routesEnum.home} />;
};

export default memo(AdminRoute);
