import { FC, useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import Content from './content';
import { Layout } from './layout.style';
import { WalletModal } from 'components/WalletModal';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { setBusinessProfile } from 'app/slices';
import { phonesNumber } from 'components/PhoneNumberInput/data';
import { getBusinessProfileInfo } from 'redux/apis/business';

const MainLayout: FC = ({ children }) => {
  const { wallet } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      try {
        if (wallet) {
          const response = await getBusinessProfileInfo(wallet);

          if (response.data) {
            const { id, name, address, email, phone, wallet_address } = response.data;
            const responseValue = {
              id,
              businessName: name,
              businessAddress: address,
              businessEmail: email,
              walletAddress: wallet_address,
              businessPhone: phone,
              prefix: phonesNumber.find((item) => phone.includes(item.dial_code))?.dial_code,
            };
            dispatch(setBusinessProfile(responseValue));
          }
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dispatch, wallet]);

  return (
    <>
      <Layout id="main-layout">
        <Header />
        <Content>
          {children}
          <Footer />
        </Content>
      </Layout>
      <WalletModal />
    </>
  );
};

export default MainLayout;
