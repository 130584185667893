import styled from 'styled-components';
import { Title as CTitle, Input, Label } from 'components';
import { Button, Checkbox as ACheckBox, Menu as AMenu, Select } from 'antd';
import { SmallerDescription } from 'components/Text/text.style';

export const Title = styled(CTitle)`
  margin-bottom: 56px;
`;

export const ContractList = styled.div`
  padding-right: 10px;
`;

export const MenuWrapper = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  gap: 30px;
`;

export const ContractWrapper = styled.div<{
  showMenu: boolean;
}>`
  ${({ showMenu }) =>
    showMenu &&
    `
    max-height: 706px;
`}
  padding: 32px 16px;
  background: linear-gradient(0deg, rgba(86, 86, 86, 0.25), rgba(86, 86, 86, 0.25)), rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 40px rgba(24, 35, 43, 0.1);
  backdrop-filter: blur(500px);
  display: flex;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;

  @media screen and (min-width: ${({ theme }) => theme.breakPoints.sm}) {
    padding: 32px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.md}) {
   max-height:100%;
  }
`;

export const ActionBtnWrap = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (min-width: 600px) {
    flex-wrap: unset;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakPoints.sm}) {
    margin-top: 40px;
  }
`;

export const BtnWrap = styled(ActionBtnWrap)`
  margin-top: 10px;
`;

export const ConfirmWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ContractContent = styled(SmallerDescription)`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #f2f2f2;
  white-space: pre-line;
  display: block;
    text-align: left;
    max-height: 400px;
    overflow: auto;
  @media screen and (min-width: ${({ theme }) => theme.breakPoints.sm}) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const HighLight = styled.a`
  color: #355f73;
  font-weight: 600;
  line-height: 30px;
`;

export const Checkbox = styled(ACheckBox)`
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: start;
  .ant-checkbox-inner {
    border-radius: 0px;
    width: 32px;
    height: 30px;
    border: 2px solid white;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #355f73;
    border-color:white;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-inner::after {
    top: 45%;
   left: 26.5%;
    width: 12.714286px;
    height: 18.142857px;
  }
  &:hover {
    .ant-checkbox-inner {
      border-color:white;
    }
  }
`;

export const Menu = styled(AMenu)`
  padding: 0px;
  padding-right: 5px;
  gap: 8px;
  width: 300px;
  max-height: 706px;
  overflow-y: auto;
  overflow-x: hidden;
  background: transparent;
  .ant-menu-item-selected {
    background-color: #3878a4 !important;
    &::after {
      display: none;
    }
  }
`;

export const MenuItem = styled(AMenu.Item)`
  padding: 40px;
  gap: 48px;
  width: 300px;
  height: 72px !important;
  background: linear-gradient(0deg, rgba(86, 86, 86, 0.25), rgba(86, 86, 86, 0.25)), rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 40px rgba(24, 35, 43, 0.1);
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #bdbdbd !important;
  &:hover {
    background: #3878a4;
  }
`;

export const ButtonOutline = styled(Button)`
  flex: 100%;
  height: 72px;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #ffffff !important;
  border: 1px solid rgba(111, 191, 236, 0.3) !important;
  text-transform: inherit;
  border-radius: 0;
  &:hover {
    background: #355f73;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakPoints.sm}) {
    font-size: 20px;
  }

  @media screen and (min-width: 600px) {
    flex: 50%;
  }
`;

export const ButtonPrimary = styled(ButtonOutline)`
  background: #355f73 !important;
`;

export const SearchBtn = styled(ButtonPrimary)`
  margin-top: 36px;
  width: 100%;
`;

export const ExportCSVBtn = styled(ButtonOutline)`
  width: 100%;
  margin-top: 10px;
`;

export const CancelBtn = styled(ButtonOutline)`
  width: 40%;
  margin-top: 10px;
  margin-right: 10px;
`;

export const ExportBtn = styled(ButtonOutline)`
  width: 60%;
  margin-top: 10px;
`;

export const AmountInput = styled(Input)``;

export const AmountLabel = styled(Label)`
  margin-bottom: 12px;
  text-transform: capitalize;
`;

export const AmountWrapper = styled.div`
  max-width: 636px;
  display: flex;
  flex-direction: column;
  margin-bottom: 56px;
  width: 100%;
`;

export const ReturnSelect = styled(Select)`
  width: 100%;

  &.ant-select:not(&.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #828282;
    box-shadow: none;
    height: 72px;

    & > span {
      display: flex;
      align-items: center;
    }
  }
`;

export const ReturnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 100%;

  @media screen and (min-width: 600px) {
    flex: 50%;
  }
`;
