import { Input } from 'antd';
import styled from 'styled-components';

export const StyledInput = styled(Input)`
  padding: 16px;
  font-size: 16px;
  line-height: 20px;
  border: 0.5px solid #828282;
  border-radius: 4px;
  outline: none;
  background: rgba(66, 66, 66, 0.1);
  width: 100%;
  ::placeholder {
    font-size: inherit;
    line-height: inherit;
    color: #828282;
  }

  &.ant-input:hover,
  &.ant-input:focus,
  &.ant-input-focused {
    border-color: #828282;
    box-shadow: none;
  }

  &.ant-input[disabled]:hover {
    border-color: #434343;
  }
  .ant-form-item-has-error :not(&.ant-input-disabled):not(&.ant-input-borderless)&.ant-input {
    border-color: #eb5757;
    box-shadow: 0px 0px 15px rgba(232, 78, 78, 0.35);
  }
  &:-webkit-autofill {
    background-color: rgba(66, 66, 66, 0.1) !important;
    color: #fff !important;
  }
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    border-color: #eb5757;
    box-shadow: 0px 0px 15px rgba(232, 78, 78, 0.35);
  }
`;

export const StyledTextArea = styled(StyledInput.TextArea)`
  border: 0.5px solid #828282;
  border-radius: 4px;
  outline: none;
  background: rgba(66, 66, 66, 0.1);
  ::placeholder {
    font-size: 16px;
    color: #828282;
  }

  &.ant-input:hover,
  &.ant-input:focus,
  &.ant-input-focused {
    border-color: #828282;
    box-shadow: none;
  }

  .ant-form-item-has-error :not(&.ant-input-disabled):not(&.ant-input-borderless)&.ant-input {
    border-color: #eb5757;
    box-shadow: 0px 0px 15px rgba(232, 78, 78, 0.35);
  }
`;
