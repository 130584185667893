import { Input, Select } from 'antd';
import styled from 'styled-components';

export const PhoneSelect = styled(Select)`
  .ant-form-item .ant-input-group &.ant-select {
    width: 120px;
  }

  &.ant-select-focused:not(&.ant-select-disabled)&.ant-select:not(&.ant-select-customize-input) .ant-select-selector {
    border-color: #828282;
    box-shadow: none;
  }

  .ant-select-item-option-content {
    display: flex;
  }
`;

export const PhoneInput = styled(Input)`
  .ant-input-group-addon {
    border: 1px solid #828282;
    border-radius: 4px 0 0 4px;

    &:first-child {
      border-right: 0;
    }

    & .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
    }

    & .ant-select-focused .ant-select-selector {
      color: #fff;
    }

    .ant-form-item-has-error & {
      color: #eb5757;
      border-color: #eb5757;
      box-shadow: 0px 0px 15px rgb(232 78 78 / 35%);
    }
  }

  .ant-input {
    border: 1px solid #828282;
    border-radius: 4px;
    padding: 16px;
    font-size: 16px;
    line-height: 20px;

    &:hover {
      border-color: #828282;
    }

    &:focus,
    &-focused {
      box-shadow: none;
    }

    .ant-form-item-has-error :not(&-disabled):not(&-borderless)& {
      border-color: #eb5757;
      box-shadow: 0px 0px 15px rgba(232, 78, 78, 0.35);
    }
  }
`;
