import styled from 'styled-components';
import { Title as CTitle } from 'components';

export const ContainerView = styled.div`
  max-width: 1410px;
  width: 100%;
  padding: 0 1rem;
  margin: 0 auto 1.875rem;

  @media screen and (min-width: 23.4375rem) {
    margin-top: 3.875rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakPoints.md}) {
    margin-bottom: 2.5rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakPoints.xxl}) {
    margin-bottom: 3.75rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
`;

export const Title = styled(CTitle)`
  margin-bottom: 30px;
  /* text-transform: capitalize; */
  text-align: center;
  font-size: 30px;
  line-height: 40px;

  @media screen and (min-width: ${({ theme }) => theme.breakPoints.md}) {
    margin-bottom: 56px;
    font-size: 40px;
    line-height: 60px;
  }
`;
