import { SyncOutlined } from '@ant-design/icons';
import { LoadingContainer, StyledSpin } from './loading.style';

const loadingIcon = <SyncOutlined style={{ fontSize: 50 }} spin />;

const Loading = ({ message }: any) => {
  return (
    <LoadingContainer>
      <StyledSpin indicator={loadingIcon} size="large" />
      <div>{message}</div>
    </LoadingContainer>
  );
};

export default Loading;
