import { CMB_CONTRACT, USDC_CONTRACT, AVAX_CONTRACT, USDT_CONTRACT } from './../../utils/constants';
import { ethers } from 'ethers';
import { getContractCMB, getContractUSDC, getContractUSDT } from './../../utils/getContract';
import BigNumber from 'bignumber.js';
import CMBAbi from 'contracts/CMB-ABI.json';

const amountETH = (totalFunds: string) => ethers.utils.parseEther(totalFunds).toString();
const amountToken = async (totalFunds: string, contract: any) => {
  const decimals = await contract.methods.decimals().call();
  return ethers.utils.parseUnits(totalFunds, decimals).toString();
};

const MAXIMUM_SOLIDITY = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

const coinAddress = (coinType: string) => {
  switch (coinType) {
    case 'USDT':
      return USDT_CONTRACT;
    case 'USDC':
      return USDC_CONTRACT;
    default:
      return AVAX_CONTRACT;
  }
};

export const handleRequestPayment = async (
  account: string | null | undefined,
  connector: any,
  totalFunds: number,
  DATA: string,
  clientAddress: string,
  quantityCharge: number,
  createdDate: number,
  expiredDate: number,
  coinType: string
) => {
  const { contract: contractCMB }: any = await getContractCMB(connector);
  const { contract: contractUSDC }: any = await getContractUSDC(connector);
  const { contract: contractUSDT }: any = await getContractUSDT(connector);
  const contractToken = coinType === 'USDT' ? contractUSDT : contractUSDC;

  // let total = '0',
  //   totalArray: string[] = [];
  // if (coinType === 'AVAX') {
  //   total = amountETH(new BigNumber(totalFunds).toString());
  //   totalArray = Array.from(Array(quantityCharge).keys()).map((el) =>
  //     amountETH(new BigNumber(totalFunds).dividedBy(quantityCharge).toString())
  //   );
  // } else {
  //   total = await amountToken(new BigNumber(totalFunds).toString(), contractToken);

  //   totalArray = await Promise.all(
  //     Array.from(Array(quantityCharge).keys()).map(async () => {
  //       return await amountToken(new BigNumber(totalFunds).dividedBy(quantityCharge).toString(), contractToken);
  //     })
  //   );
  // }

  const currentCoinAddress = coinAddress(coinType);
  try {
    var payment_id;

    await contractCMB.methods
      .requestPayment(
        clientAddress,
        currentCoinAddress,
        DATA,
        await amountToken(new BigNumber(totalFunds).dividedBy(quantityCharge).toString(), contractToken),
        quantityCharge,
        expiredDate
      )
      .call({
        from: account,
      });
    await contractCMB.methods
      .requestPayment(
        clientAddress,
        currentCoinAddress,
        DATA,
        await amountToken(new BigNumber(totalFunds).dividedBy(quantityCharge).toString(), contractToken),
        quantityCharge,
        expiredDate
      )
      .send({
        from: account,
      })
      .on('transactionHash', async (res: any) => {
        //AFTER CLICK CONFIRM ON METAMASK
      })
      .on('receipt', async (res: any) => {
        //AFTER TRANSACTION SUCCESS
        payment_id = res.events?.RequestedPayment?.returnValues.paymentId;
      });
    return payment_id;
  } catch (err) {
    //HANDLE MESSAGE ERROR
    console.log({ err });
    throw err;
  }
};

export const handleClaimPayment = async (
  account: string | null | undefined,
  connector: any,
  paymentId: string,
  treatmentId: number
) => {
  const { contract: contractCMB }: any = await getContractCMB(connector);
  try {
    await contractCMB.methods.claim(paymentId).call({
      from: account,
    });
    await contractCMB.methods
      .claim(paymentId)
      .send({
        from: account,
      })
      .on('transactionHash', async () => {
        //AFTER CLICK CONFIRM ON METAMASK
      })
      .on('receipt', async () => {
        //AFTER TRANSACTION SUCCESS
      });
  } catch (err) {
    //HANDLE MESSAGE ERROR
    console.log({ err });
    throw err;
  }
};

export const handleEscrowMoney = async (
  account: string | null | undefined,
  connector: any,
  // get from db
  paymentId: string,
  payAmount: number,
  coinType: string
) => {
  const { contract: contractCMB }: any = await getContractCMB(connector);
  const { contract: contractUSDC }: any = await getContractUSDC(connector);
  const { contract: contractUSDT }: any = await getContractUSDT(connector);

  let value = '0';
  if (coinType === 'AVAX') {
    value = amountETH(payAmount.toString()).toString();
  } else {
    const contractToken = coinType === 'USDT' ? contractUSDT : contractUSDC;
    value = await amountToken(payAmount.toString(), contractToken);
  }

  const payToken = async () => {
    if (coinType !== 'AVAX') {
      const balance = await checkBalance(account, connector, coinType);
      if (balance < +value) {
        throw new Error(`Not enough ${coinType}`);
      }
    }

    await contractCMB.methods.pay(paymentId, value).call({
      from: account,
      value: coinType !== 'AVAX' ? 0 : value,
    });

    await contractCMB.methods
      .pay(paymentId, value)
      .send({
        from: account,
        value: coinType !== 'AVAX' ? 0 : value,
      })
      .on('transactionHash', async () => {
        //AFTER CLICK CONFIRM ON METAMASK
      })
      .on('receipt', async () => {
        //AFTER TRANSACTION SUCCESS
      });
  };

  try {
    if (coinType !== 'AVAX') {
      const allowance = await checkAllowance(account, connector, coinType);

      if (allowance > 0) {
        await payToken();
      } else {
        if (coinType === 'USDT') {
          await approveUSDT(account, connector, payToken);
        } else {
          await approveUSDC(account, connector, payToken);
        }
      }
    } else {
      await payToken();
    }
  } catch (err) {
    //HANDLE MESSAGE ERROR
    console.log({ err });
    throw err;
  }
};

export const handleCancelContract = async (
  account: string | null | undefined,
  connector: any,
  // get from db
  paymentId: string
) => {
  const { contract: contractCMB }: any = await getContractCMB(connector);
  try {
    await contractCMB.methods.cancelPayment(paymentId).call({
      from: account,
    });
    await contractCMB.methods
      .cancelPayment(paymentId)
      .send({
        from: account,
      })
      .on('transactionHash', async () => {
        //AFTER CLICK CONFIRM ON METAMASK
      })
      .on('receipt', async () => {
        //AFTER TRANSACTION SUCCESS
      });
  } catch (err) {
    //HANDLE MESSAGE ERROR
    console.log({ err });
    throw err;
  }
};

export const handleWithdrawContract = async (
  account: string | null | undefined,
  connector: any,
  // get from db
  paymentId: string
) => {
  // const { contract: contractCMB }: any = await getContractCMB(connector);
  const ethereum = (window as any).ethereum;
  const accounts = await ethereum.request({
    method: "eth_requestAccounts",
  });

  const provider = new ethers.providers.Web3Provider(ethereum)
  const walletAddress = accounts[0]    // first account in MetaMask
  const signer = provider.getSigner(walletAddress)
  const ethersContract = new ethers.Contract(CMB_CONTRACT, CMBAbi, signer)

  try {
    let res= await ethersContract.clientWithdrawExpiredPayment(paymentId)
    await res.wait()

    // await contractCMB.methods.clientWithdrawExpiredPayment(paymentId).call({
    //   from: account,
    // });
    // await contractCMB.methods
    //   .clientWithdrawExpiredPayment(paymentId)
    //   .send({
    //     from: account,
    //   })
    //   .on('transactionHash', async () => {
    //     //AFTER CLICK CONFIRM ON METAMASK
    //   })
    //   .on('receipt', async () => {
    //     //AFTER TRANSACTION SUCCESS
    //   });
  } catch (err) {
    //HANDLE MESSAGE ERROR

    console.log({ err });
    throw err;
  }
};

export const ownerWithdrawAfterExpired = async (  
  account: string | null | undefined,
  connector: any,
  paymentId: string
) => {
  // const { contract: contractCMB }: any = await getContractCMB(connector);

  const ethereum = (window as any).ethereum;
  const accounts = await ethereum.request({
    method: "eth_requestAccounts",
  });

  const provider = new ethers.providers.Web3Provider(ethereum)
  const walletAddress = accounts[0]    // first account in MetaMask
  const signer = provider.getSigner(walletAddress)
  const ethersContract = new ethers.Contract(CMB_CONTRACT, CMBAbi, signer)

  try {
   let res= await ethersContract.ownerWithdrawExpiredPayment(paymentId)
   await res.wait()

    // let gasAmount = await contractCMB.methods.ownerWithdrawExpiredPayment(paymentId).estimateGas({ from: account });
    // await contractCMB.methods.ownerWithdrawExpiredPayment(paymentId).call({
    //   from: account,
    //   gas: gasAmount * 2,
    // });
    // await contractCMB.methods
    //   .ownerWithdrawExpiredPayment(paymentId)
    //   .send({
    //     from: account,
    //     gas: gasAmount * 2,

    //   })
    //   .on('transactionHash', async () => {
    //     //AFTER CLICK CONFIRM ON METAMASK
    //   })
    //   .on('receipt', async () => {
    //     //AFTER TRANSACTION SUCCESS
    //   });
  } catch (err) {
    //HANDLE MESSAGE ERROR
    console.log({ err });
    throw err;
  }
};

export const handleConfirmToRelease = async (
  account: string | null | undefined,
  connector: any,
  paymentId: string,
  treatment_id: number
) => {
  const { contract: contractCMB }: any = await getContractCMB(connector);
  try {
    await contractCMB.methods.confirmToRelease(paymentId).call({
      from: account,
    });

    await contractCMB.methods
      .confirmToRelease(paymentId)
      .send({
        from: account,
      })
      .on('transactionHash', async () => {
        //AFTER CLICK CONFIRM ON METAMASK
      })
      .on('receipt', async () => {
        //AFTER TRANSACTION SUCCESS
      });
  } catch (err) {
    //HANDLE MESSAGE ERROR
    console.log({ err });
    throw err;
  }
};

export const checkAllowance = async (account: string | null | undefined, connector: any, coinType: string) => {
  let contractToken;
  if (coinType === 'USDT') {
    contractToken = await (await getContractUSDT(connector)).contract;
  } else {
    contractToken = await (await getContractUSDC(connector)).contract;
  }
  let allowance = 0;
  try {
    allowance = await contractToken.methods.allowance(account, CMB_CONTRACT).call();
  } catch (err) {
    //HANDLE MESSAGE ERROR
    console.log({ err });
    throw err;
  }
  return allowance;
};

export const checkBalance = async (account: string | null | undefined, connector: any, coinType: string) => {
  let contractToken;
  if (coinType === 'USDT') {
    contractToken = (await getContractUSDT(connector)).contract;
  } else {
    contractToken = (await getContractUSDC(connector)).contract;
  }
  let balance = 0;
  try {
    balance = +(await contractToken.methods.balanceOf(account).call());
  } catch (err) {
    //HANDLE MESSAGE ERROR
    console.log({ err });
    throw err;
  }
  return balance;
};

export const approveUSDC = async (account: string | null | undefined, connector: any, callback: Function) => {
  const { contract: contractUSDC }: any = await getContractUSDC(connector);
  try {
    await contractUSDC.methods.approve(CMB_CONTRACT, MAXIMUM_SOLIDITY).call({
      from: account,
    });
    return new Promise<void>((res, rej) => {
      contractUSDC.methods
        .approve(CMB_CONTRACT, MAXIMUM_SOLIDITY)
        .send({
          from: account,
        })
        .on('receipt', async () => {
          try {
            await callback();
            res();
          } catch (error) {
            rej(error);
          }
        })
        .on('error', (err: any) => {
          rej(err);
        });
    });
  } catch (err) {
    //HANDLE MESSAGE ERROR
    console.log({ err });
    throw err;
  }
};

export const approveUSDT = async (account: string | null | undefined, connector: any, callback: Function) => {
  const { contract: contractUSDT }: any = await getContractUSDT(connector);
  try {
    await contractUSDT.methods.approve(CMB_CONTRACT, MAXIMUM_SOLIDITY).call({
      from: account,
    });
    return new Promise<void>((res, rej) => {
      contractUSDT.methods
        .approve(CMB_CONTRACT, MAXIMUM_SOLIDITY)
        .send({
          from: account,
        })
        .on('receipt', async () => {
          try {
            await callback();
            res();
          } catch (error) {
            rej(error);
          }
        })
        .on('error', (err: any) => {
          rej(err);
        });
    });
  } catch (err) {
    //HANDLE MESSAGE ERROR
    console.log({ err });
    throw err;
  }
};

export const getAdmin = async (connector: any) => {
  try {
    const { contract: contractCMB }: any = await getContractCMB(connector);
    const response = await contractCMB.methods.owner().call();

    return response;
  } catch (error: any) {
    throw error;
  }
  // return '0xA151a7E5Be1C07ecaD883Ad59bac26E412327e11'
};

export const handleReturnMoney = async (
  account: string | null | undefined,
  connector: any,
  // get from db
  paymentId: string,
  receiver: string
) => {
  const { contract: contractCMB }: any = await getContractCMB(connector);
  try {
    await contractCMB.methods.returnMoney(paymentId, receiver).call({
      from: account,
    });
    await contractCMB.methods
      .returnMoney(paymentId, receiver)
      .send({
        from: account,
      })
      .on('transactionHash', async () => {
        //AFTER CLICK CONFIRM ON METAMASK
      })
      .on('receipt', async () => {
        //AFTER TRANSACTION SUCCESS
      });
  } catch (err) {
    //HANDLE MESSAGE ERROR
    console.log({ err });
    throw err;
  }
};

export const handleConfirmProvideServices = async (
  account: string | null | undefined,
  connector: any,
  // get from db
  paymentId: string,
  currentTreatment: number
) => {
  const { contract: contractCMB }: any = await getContractCMB(connector);
  try {
    await contractCMB.methods.confirmProvideServices(paymentId).call({
      from: account,
    });
    await contractCMB.methods
      .confirmProvideServices(paymentId)
      .send({
        from: account,
      })
      .on('transactionHash', async () => {
        //AFTER CLICK CONFIRM ON METAMASK
      })
      .on('receipt', async () => {
        //AFTER TRANSACTION SUCCESS
      });
  } catch (err) {
    //HANDLE MESSAGE ERROR
    console.log({ err });
    throw err;
  }
};
