import { FC, memo } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { routesEnum } from 'pages/Router';

const PrivateRoute: FC<RouteProps> = (props) => {
  const { access_token } = useAppSelector((state) => state.auth);
  const location: any = useLocation();
  if (access_token || [routesEnum.BOClaimPayment, routesEnum.BOConfirm].includes(location.pathname)) {
    return <Route {...props} />;
  }

  return <Redirect to={routesEnum.unauthorize} />;
};

export default memo(PrivateRoute);
