import { Button, Menu as AMenu } from 'antd';
import { FillButton } from 'components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Logo = styled(Link)`
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakPoints.xl}) {
    display: initial;
  }
`;

export const LogoTablet = styled(Link)`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  @media screen and (min-width: ${({ theme }) => theme.breakPoints.xl}) {
    display: none;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
  display: none;

  & > div.ant-tabs {
    flex: 70%;
  }

  & > button {
    flex: 15%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakPoints.xl}) {
    display: flex;
  }
`;

export const CollapseMenu = styled.div`
  display: initial;
  @media screen and (min-width: ${({ theme }) => theme.breakPoints.xl}) {
    display: none;
  }
`;

export const CollapseBtn = styled(FillButton)`
  display: initial;
  @media screen and (min-width: ${({ theme }) => theme.breakPoints.xl}) {
    display: none;
  }
`;

export const TabletMenuMask = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  display: none;
`;

export const TabletMenu = styled.div`
  position: fixed;
  top: 118px;
  left: 0;
  width: 232px;
  z-index: 999;
  background: linear-gradient(141deg, rgba(33, 139, 115, 1) 0%, #4c5757 30%, rgb(76 87 87) 100%);
  height: calc(100vh - 118px);
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.3s ease-in-out;

  &.active {
    transform: translate3d(0, 0, 0);

    ${TabletMenuMask} {
      display: block;
    }
  }
  .ant-space {
    width: 100%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakPoints.xl}) {
    display: none;
  }
`;

export const MenuContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 1000;
  text-align: center;
`;

export const Menu = styled(AMenu)`
  background: transparent;
  border: none;
  transition: none;
  width: 100%;

  li:last-child {
    border-bottom: none;
  }

  .ant-menu-item {
    padding: 0.9656rem 0.9656rem;
    height: 5rem;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    color: #fff;
    margin: 0;
    transition: 0.3s ease-in-out;
    justify-content: center;
    &.ant-menu-item {
      user-select: none;
      .ant-menu-title-content {
        font-size: 1rem;
        line-height: 1rem;
      }
      margin-bottom: 0;
      &-active,
      &-selected {
        background: #09262e;
        a {
          color: #fff !important;
        }
      }
    }
  }
`;

export const MenuItem = styled(Menu.Item)``;

export const HeaderContainer = styled.header`
  font-size: 14px;
  background: linear-gradient(90deg, rgba(33, 139, 115, 1) 0%, rgb(76 87 87) 30%, rgb(76 87 87) 100%);
  backdrop-filter: blur(106px);
  padding: 16px 34px;
  width: 100%;
  height: 118px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  @media screen and (min-width: ${({ theme }) => theme.breakPoints.xl}) {
    padding: 32px 68px;
  }
`;

export const Image = styled.img`
  width: 150px;
  height: 60px;
`;

export const ButtonOutline = styled(Button)`
  width: 100%;
  height: 48px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff !important;
  border: none;
  text-transform: inherit;
  border-radius: 0;
  &:hover {
    background: #355f73;
  }
`;

export const ButtonPrimary = styled(ButtonOutline)`
  background: #355f73 !important;
  img {
    margin-right: 10px;
  }
`;

export const Navlist = styled.ul`
  display: flex;
  align-items: center;
  margin-bottom: 0;
  height: 100%;
  gap: 10px;
  @media screen and (min-width: ${({ theme }) => theme.breakPoints.xl}) {
    gap: 32px;
  }
`;

export const NavItem = styled.li`
  list-style-type: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  a {
    color: #fff;

    &:before {
      content: '';
      width: 100%;
      height: 5px;
      background: #6fbfec;
      position: absolute;
      bottom: -32px;
      opacity: 0;
    }

    &.active {
      color: #6fbfec;

      &:before {
        opacity: 1;
      }
    }

    &:hover {
      color: #6fbfec;
    }
  }
`;

export const WalletInfo = styled.div`
  background: #355f73;
  padding: 14px 15px;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;
