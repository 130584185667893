import { useDynamicRoutes } from 'hooks';
import { lazy, FC } from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';
import Admin from './admin';
import { AdminRoute, PrivateRoute } from './routes';

export enum routesEnum {
  home = '/',
  unauthorize = '/403',
  notfound = '/404',
  disclosure = '/disclosure',
  privacyPolicy = '/privacy-policy',
  BOConfirm = '/bo/confirm',
  createContract = '/create-contract',
  newContract = '/new-contract',
  smartContract = '/smart-contract',
  smartContractReview = '/smart-contract-review',
  BOClaimPayment = '/bo/claim-payment',
  clientPayment = '/clientPayment',
  clientConfirm = '/client/confirm',
  clientCancel = '/client/cancel',
  clientWithdraw = '/client/expired',
  admin = '/admin',
}

const routes: RouteProps[] = [
  {
    path: routesEnum.unauthorize,
    exact: true,
    component: lazy(() => import('./403')),
  },
  {
    path: routesEnum.home,
    exact: true,
    component: lazy(() => import('./Home')),
  },
  {
    path: routesEnum.disclosure,
    exact: true,
    component: lazy(() => import('./Disclosure')),
  },
  {
    path: routesEnum.privacyPolicy,
    exact: true,
    component: lazy(() => import('./PrivacyPolicy')),
  },
  {
    path: routesEnum.clientPayment,
    exact: true,
    component: lazy(() => import('./ClientPayment')),
  },
  {
    path: routesEnum.clientConfirm,
    exact: true,
    component: lazy(() => import('./ClientConfirm')),
  },
  {
    path: routesEnum.clientCancel,
    exact: true,
    component: lazy(() => import('./ClientCancel')),
  },
  {
    path: routesEnum.clientWithdraw,
    exact: true,
    component: lazy(() => import('./ClientWithdraw')),
  },
];

const privateRoutes: RouteProps[] = [
  {
    path: routesEnum.smartContract,
    exact: true,
    component: lazy(() => import('./SearchContract')),
  },
  {
    path: routesEnum.BOClaimPayment,
    exact: true,
    component: lazy(() => import('./BOClaimPayment')),
  },
  {
    path: routesEnum.BOConfirm,
    exact: true,
    component: lazy(() => import('./BOConfirm')),
  },
  {
    path: routesEnum.createContract,
    exact: true,
    component: lazy(() => import('./CreateContract')),
  },
  {
    path: routesEnum.newContract,
    exact: true,
    component: lazy(() => import('./NewContract')),
  },
  {
    path: routesEnum.smartContractReview,
    exact: true,
    component: lazy(() => import('./SearchContract/ContractReview')),
  },
];

export const Routes: FC = () => {
  const dynamicRoutes = useDynamicRoutes();
  const normalRoute = {
    path: dynamicRoutes.createBusiness,
    exact: true,
    component: lazy(() => import('./Business/Create')),
  };

  return (
    <Switch>
      {routes.concat(normalRoute).map((route) => (
        <Route path={route.path} key={route.path as string} exact={route.exact} component={route.component} />
      ))}
      {privateRoutes.map((route) => (
        <PrivateRoute path={route.path} key={route.path as string} exact={route.exact} component={route.component} />
      ))}
      <AdminRoute path={routesEnum.admin} exact>
        <Admin />
      </AdminRoute>
      <Route path="*" component={lazy(() => import('./404'))} />
    </Switch>
  );
};
