import { FC, useEffect, useState } from 'react';
import {
  ButtonPrimary,
  HeaderContainer,
  Image,
  ActionsWrapper,
  NavItem,
  Navlist,
  WalletInfo,
  CollapseMenu,
  CollapseBtn,
  TabletMenu,
  MenuContent,
  TabletMenuMask,
  MenuItem,
  Menu,
  Logo,
  LogoTablet,
} from './header.style';
import logo from 'assets/images/logo.png';
import avaxLogo from 'assets/images/avax-logo.png';
import { NETWORK_CHAIN_IDS } from 'utils/constants';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDynamicRoutes, useWallet, useWalletModal } from 'hooks';
import { switchNetworks } from 'utils/switchNetwork';
import { routesEnum } from 'pages/Router';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { changeModalVisible, clearContract, setAuthData, setBusinessProfile } from 'app/slices';
import { Popover, Space } from 'antd';
import { Notification } from 'components/Notification';
import { MenuOutlined } from '@ant-design/icons';

const Header: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { toggleOpen } = useWalletModal();
  const { chainId, account, active } = useWallet();
  const dynamicRoutes = useDynamicRoutes();
  const [walletAddress, setWalletAddress] = useState('');
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [connectVisible, setConnectVisible] = useState(false);
  const [changeNetworkLoading, setChangeNetworkLoading] = useState(false);
  const { wallet, access_token, isAdmin } = useAppSelector((state) => state.auth);

  const HEADER_TABPANE = [
    {
      id: 'business',
      name: 'Business Profile',
      redirect: dynamicRoutes.createBusiness,
    },
    {
      id: 'contract',
      name: 'Create Contract',
      redirect: routesEnum.createContract,
    },
    {
      id: 'smart',
      name: 'Smart Contract Review',
      redirect: routesEnum.smartContract,
    },
  ];

  const isConnectedToAvax = chainId === NETWORK_CHAIN_IDS.avax;

  const handleChangePage = () => {
    dispatch(clearContract());
  };

  const handleLogout = () => {
    localStorage.removeItem('wallet');
    localStorage.removeItem('token');
    localStorage.removeItem('isAdmin');
    dispatch(
      setAuthData({
        wallet: null,
        access_token: null,
        isAdmin: false,
      })
    );
    dispatch(setBusinessProfile(null));
    history.push(routesEnum.home);
    Notification('info', '', 'Logged out');
  };
  const content = <div onClick={handleLogout}>Log out</div>;

  const handleChangeNetwork = async () => {
    if (typeof (window as any).ethereum === 'undefined') {
      dispatch(changeModalVisible(true));
      return;
    }

    if (isConnectedToAvax) {
      Notification('success', '', 'You have already changed network');
      return;
    }

    try {
      setChangeNetworkLoading(true);
      await switchNetworks();
    } catch (error) {
      console.error(error);
    } finally {
      setChangeNetworkLoading(false);
    }
  };

  const handleConnectWallet = () => {
    if (!account) {
      toggleOpen();
    } else {
      Notification('info', '', 'Your wallet is already connected');
    }
  };

  useEffect(() => {
    const showConnectBtnRoute = Object.keys(routesEnum)
      .map((item) => routesEnum[item as keyof typeof routesEnum])
      .filter((route) => route.includes('client'));

    if (showConnectBtnRoute.some((item) => item === location.pathname) && !access_token) {
      setConnectVisible(true);
    } else {
      setConnectVisible(false);
    }
  }, [access_token, location.pathname]);

  useEffect(() => {
    if (account) {
      setWalletAddress(account);
    } else {
      setWalletAddress('');
    }
  }, [account]);

  const toggleCollapseMenu = () => {
    setCollapsed(!collapsed);
  };

  return (
    <HeaderContainer>
      <Logo to="/">
        <Image alt="" src={logo} />
      </Logo>
      <ActionsWrapper>
        {access_token && (
          <Navlist>
            {HEADER_TABPANE.map((tabItem) => (
              <NavItem key={tabItem.id} onClick={handleChangePage}>
                <NavLink to={tabItem.redirect} activeClassName="active">
                  {tabItem.name}
                </NavLink>
              </NavItem>
            ))}
            {isAdmin && (
              <NavItem>
                <NavLink to={routesEnum.admin} activeClassName="active">
                  CMB Claimable Contracts
                </NavLink>
              </NavItem>
            )}
          </Navlist>
        )}
        {connectVisible &&
          (walletAddress.length === 0 ? (
            <ButtonPrimary onClick={handleConnectWallet}>Connect wallet</ButtonPrimary>
          ) : (
            <WalletInfo>
              {walletAddress.slice(0, 6)}...{walletAddress.slice(36, 42)}
            </WalletInfo>
          ))}
        {isConnectedToAvax ? (
          <ButtonPrimary>
            <img src={avaxLogo} alt="logo" />
            AVAX Network
          </ButtonPrimary>
        ) : (
          active && (
            <ButtonPrimary onClick={handleChangeNetwork} loading={changeNetworkLoading}>
              Switch to AVAX Network
            </ButtonPrimary>
          )
        )}
        {wallet && (
          <Popover content={content} title="Title" placement="bottomRight">
            <ButtonPrimary>
              {wallet.slice(0, 6)}...{wallet.slice(36, 42)}
            </ButtonPrimary>
          </Popover>
        )}
      </ActionsWrapper>
      <CollapseMenu>
        <CollapseBtn icon={<MenuOutlined />} onClick={toggleCollapseMenu} />
        <TabletMenu className={collapsed ? 'active' : ''}>
          <TabletMenuMask onClick={toggleCollapseMenu} />
          <MenuContent>
            <Link to="/">
              <Image style={{ marginTop: 20 }} alt="" src={logo} />
            </Link>
            {access_token && (
              <Menu>
                {HEADER_TABPANE.map((tabItem) => (
                  <MenuItem key={tabItem.id} onClick={handleChangePage}>
                    <NavLink to={tabItem.redirect} activeClassName="active">
                      {tabItem.name}
                    </NavLink>
                  </MenuItem>
                ))}
                {isAdmin && (
                  <MenuItem>
                    <NavLink to={routesEnum.admin} activeClassName="active">
                    CMB Claimable Contracts
                    </NavLink>
                  </MenuItem>
                )}
              </Menu>
            )}
          </MenuContent>
          <Space style={{ marginTop: 20 }} direction="vertical">
            {connectVisible &&
              (walletAddress.length === 0 ? (
                <ButtonPrimary onClick={handleConnectWallet}>Connect wallet</ButtonPrimary>
              ) : (
                <WalletInfo>
                  {walletAddress.slice(0, 6)}...{walletAddress.slice(36, 42)}
                </WalletInfo>
              ))}
            {isConnectedToAvax ? (
              <ButtonPrimary>
                <img src={avaxLogo} alt="logo" />
                AVAX Network
              </ButtonPrimary>
            ) : (
              active && (
                <ButtonPrimary onClick={handleChangeNetwork} loading={changeNetworkLoading}>
                  Switch to AVAX Network
                </ButtonPrimary>
              )
            )}
            {wallet && (
              <Popover content={content} title="Title" trigger="click" placement="bottomRight">
                <ButtonPrimary>
                  {wallet.slice(0, 6)}...{wallet.slice(36, 42)}
                </ButtonPrimary>
              </Popover>
            )}
          </Space>
        </TabletMenu>
      </CollapseMenu>
        <LogoTablet to="/">
        <Image alt="" src={logo} />
      </LogoTablet>
    </HeaderContainer>
  );
};

export default Header;
