import { DefaultTheme } from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

declare module 'styled-components' {
  export interface DefaultTheme {
    typography: {
      16: string;
      20: string;
      24: string;
      28: string;
      29: string;
      32: string;
      34: string;
      39: string;
      40: string;
      60: string;
    };
    breakPoints: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
      xxl: string;
    };
    primary: string;
    secondary: string;
    background: string;
  }
}

export const theme = {
  typography: {
    16: '16px',
    20: '20px',
    24: '24px',
    28: '28px',
    29: '29px',
    32: '32px',
    34: '34px',
    39: '39px',
    40: '40px',
    60: '60px',
  },
  breakPoints: BREAKPOINTS,
};

export const lightTheme: DefaultTheme = {
  ...theme,
  primary: '#ABFF00',
  secondary: '#666',
  background: '#D0EFD1',
};

export const darkTheme: DefaultTheme = {
  ...theme,
  primary: '#fff',
  secondary: '#cacaca',
  background: '#D0EFD1',
};
