import { Spin } from 'antd';
import styled from 'styled-components';

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(29, 29, 29, 0.342);
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledSpin = styled(Spin)`
  color: #51a3d2;
`;

