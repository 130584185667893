import styled from 'styled-components';
import { Spin } from 'antd';

export const StyledLoading = styled(Spin)`
  color: #51a3d2;

  span.ant-spin-dot {
    font-size: 50px;
  }
`;
