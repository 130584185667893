import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CommonLink = styled(Link)`
  text-decoration: none;
`;

export const NavBarText = styled(CommonLink)`
  font-size: ${({ theme }) => theme.typography[16]};
  line-height: ${({ theme }) => theme.typography[20]};
  color: ${({ theme }) => theme.primary};
`;

export const H1 = styled.h1`
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography[40]};
  line-height: ${({ theme }) => theme.typography[60]};
  color: #e0e0e0;
`;

export const H2 = styled.h2`
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography[28]};
  line-height: ${({ theme }) => theme.typography[34]};
  color: #333333;
`;

export const H3 = styled.h3`
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography[24]};
  line-height: ${({ theme }) => theme.typography[39]};
  color: #e0e0e0;
`;

export const H6 = styled.h6`
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography[16]};
  line-height: ${({ theme }) => theme.typography[16]};
  color: #e0e0e0;
`;

export const SmallerDescription = styled.span`
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography[20]};
  line-height: ${({ theme }) => theme.typography[24]};
  color: #a4a4a4;
  text-align: center;
`;

export const TextForm = styled.span`
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography[16]};
  line-height: ${({ theme }) => theme.typography[20]};
  color: #e0e0e0;
`;
