import { USDC_CONTRACT, USDT_CONTRACT } from './constants';
/* eslint-disable import/prefer-default-export */
import Web3 from 'web3';

import contractCMB from 'contracts/CMB-ABI.json';
import contractUSDC from 'contracts/USDC-ABI.json';
import contractUSDT from 'contracts/USDT-ABI.json';

import { CMB_CONTRACT } from './constants';
import { Notification } from 'components/Notification';

export const getContractCMB = async (connector: any) => {
  if (!connector) throw Notification('error', 'No connector found', '');
  const walletProvider = await connector.getProvider();
  const web3 = new Web3(walletProvider);

  const contractAbi: any = contractCMB;

  const contractAddress = CMB_CONTRACT;

  return {
    contract: new web3.eth.Contract(contractAbi, contractAddress),
  };
};

export const getContractUSDC = async (connector: any) => {
  if (!connector) throw Notification('error', 'No connector found', '');
  const walletProvider = await connector.getProvider();
  const web3 = new Web3(walletProvider);

  const contractAbi: any = contractUSDC;

  const contractAddress = USDC_CONTRACT;

  return {
    contract: new web3.eth.Contract(contractAbi, contractAddress),
  };
};

export const getContractUSDT = async (connector: any) => {
  if (!connector) throw Notification('error', 'No connector found', '');
  const walletProvider = await connector.getProvider();
  const web3 = new Web3(walletProvider);

  const contractAbi: any = contractUSDT;

  const contractAddress = USDT_CONTRACT;

  return {
    contract: new web3.eth.Contract(contractAbi, contractAddress),
  };
};
