import { FC, memo, ChangeEvent } from 'react';
import { InputProps } from './input.props';
import { StyledInput, StyledTextArea } from './input.style';

const Input: FC<InputProps> = ({ value = '', onChange, rows, ...rest }) => {
  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currVal = e.target.value;

    onChange?.(currVal);
  };

  const onTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const currVal = e.target.value;

    onChange?.(currVal);
  };

  return rows ? (
    <StyledTextArea rows={rows} value={value} onChange={onTextChange} {...rest} />
  ) : (
    <StyledInput value={value} onChange={onValueChange} {...rest} />
  );
};

export default memo(Input);
