import { DownloadOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { useAppSelector } from 'app/hooks';
import { Loading } from 'components';
import Container from 'components/Container';
import ContractDetail from 'components/ContractDetail';
import { Checkbox, ContractWrapper, ExportCSVBtn } from 'components/ContractDetail/contract.style';
import { Notification } from 'components/Notification';
import {
  BtnWrap,
  CancelBtn,
  ContractList,
  ExportBtn,
  Menu,
  MenuItem,
  MenuWrapper,
} from 'pages/SearchContract/ContractReview/contractReview.style';
import { FC, memo, useEffect, useState } from 'react';
import { exportContract, getContractsHandleByAdmin } from 'redux/apis/contract';
import { ContractItem } from 'types/contract';
import { ContractsWrapper, LoadmoreBtn, Title, Wrapper } from './admin.style';

const Admin: FC = () => {
  const [loading, setLoading] = useState(false);
  const { wallet } = useAppSelector((state) => state.auth);
  const [exportFiles, setExportFiles] = useState<string[] | null>(null);
  const [currentContract, setCurrentContract] = useState<ContractItem | null>(null);
  const [contracts, setContracts] = useState<ContractItem[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const handleAddContract = (event: any, contractId: string) => {
    if (exportFiles) {
      if (event.target.checked) {
        setExportFiles([...exportFiles, contractId]);
      } else {
        const newExportFiles = exportFiles.filter((item) => item !== contractId);
        setExportFiles(newExportFiles);
      }
    } else {
      setExportFiles([contractId]);
    }
  };

  const handleExportCSV = async () => {
    if (exportFiles) {
      const response = await exportContract(exportFiles);
      if (response.status === 201) {
        const blob = new Blob([response.data as string], { type: 'data:text/csv;charset=utf-8,' });
        const blobURL = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.download = `list-contract.csv`;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(':');
        anchor.click();
        Notification('success', 'Exporting...', '');
      } else {
        Notification('error', 'Something wrong', '');
      }
    }
  };

  const handleChangeContract = (menuItem: any) => {
    const { key } = menuItem;
    const matchContract = contracts?.find((item) => item.id === key);
    if (matchContract) {
      setCurrentContract(matchContract);
    }
  };

  const handleLoadmoreContracts = async () => {
    if (wallet) {
      setLoading(true);
      try {
        const response = await getContractsHandleByAdmin(page);

        if (response.status === 200) {
          const listContract = response.data.contracts;
          setTotal(response.data.count);
          setContracts((prev) => [...prev, ...listContract]);
          setCurrentContract(contracts[0] || listContract[0]);
          setPage((prev) => prev + 1);
        }
        setLoading(false);
      } catch (error: any) {
        console.log(error);
        Notification('error', '', error.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await handleLoadmoreContracts();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultKeys = currentContract ? [currentContract.id] : [];

  return (
    <Container>
      <Wrapper>
        <Title level={1}>CMB Claimable Contracts</Title>
        {loading ? (
          <Loading />
        ) : contracts.length > 0 ? (
          <>
            <MenuWrapper>
              <ContractList>
                <Menu
                  defaultSelectedKeys={defaultKeys}
                  onClick={handleChangeContract}
                  defaultOpenKeys={defaultKeys}
                  mode="inline"
                >
                  {contracts.map((item, index) => (
                    <MenuItem key={item.id}>
                      {exportFiles ? (
                        <Checkbox onChange={(event: any) => handleAddContract(event, item.id)}>
                          Contract #{item.payment_id}
                        </Checkbox>
                      ) : (
                        `Contract #${item.payment_id}`
                      )}
                    </MenuItem>
                  ))}
                </Menu>
                {exportFiles ? (
                  <BtnWrap>
                    <CancelBtn onClick={() => setExportFiles(null)}>Cancel</CancelBtn>
                    <ExportBtn icon={<DownloadOutlined />} disabled={!exportFiles.length} onClick={handleExportCSV}>
                      Export
                    </ExportBtn>
                  </BtnWrap>
                ) : (
                  <ExportCSVBtn icon={<DownloadOutlined />} onClick={() => setExportFiles([])}>
                    Export .csv file
                  </ExportCSVBtn>
                )}
              </ContractList>
              <ContractsWrapper>
                <ContractDetail
                  showMenu={true}
                  setCurrentContract={setCurrentContract}
                  currentContract={currentContract}
                />
                {contracts.length > 0 && (
                  <LoadmoreBtn onClick={handleLoadmoreContracts} disabled={contracts.length === total}>
                    Load more contracts
                  </LoadmoreBtn>
                )}
              </ContractsWrapper>
            </MenuWrapper>
          </>
        ) : (
          <ContractWrapper showMenu={true}>
            <Empty description="No Result Found" />
          </ContractWrapper>
        )}
      </Wrapper>
    </Container>
  );
};

export default memo(Admin);
