import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IModal {
  visible: boolean;
}

const initialState: IModal = {
  visible: false,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    changeModalVisible: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload !== undefined) {
        state.visible = action.payload;
      } else {
        state.visible = !state.visible;
      }
    },
  },
});

export const { changeModalVisible } = modalSlice.actions;

export default modalSlice.reducer;
