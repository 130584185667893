export const switchNetworks = async () => {
  const { ethereum } = window as any;
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0xA86a' }],
    });
  } catch (switchError: any) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0xA86a',
             chainName: 'Avalanche Network',
              rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'] /* ... */,
              blockExplorerUrls: ['https://snowtrace.io'],
              nativeCurrency: {
                name: 'AVAX',
                symbol: 'AVAX',
                decimals: 18,
              },
            },
          ],
        });
      } catch (addError) {
        // handle "add" error
      }
    }
    else if (switchError.code === -32603) {
      try {
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0xA86a',
             chainName: 'Avalanche Network',
              rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'] /* ... */,
              blockExplorerUrls: ['https://snowtrace.io'],
              nativeCurrency: {
                name: 'AVAX',
                symbol: 'AVAX',
                decimals: 18,
              },
            },
          ],
        });
      } catch (addError) {
        // handle "add" error
      }
    }
    // handle other "switch" errors
  }
};
