/* eslint-disable no-underscore-dangle */
export const __prod__ = process.env.NODE_ENV === 'production';

export const CONTRACT_ADDRESS = process.env.REACT_APP_SC_ADDRESS || '0xf7933fb707e79256aB449D53F8bF86561cCc4dFD';

export const API_ENDPOINT = process.env.REACT_APP_API_END_POINT || 'https://api.cryptomybiz.io/';

export const NETWORK_CHAIN_IDS = {
  mainnet: 1,
  ropsten: 3,
  rinkeby: 4,
  goerli: 5,
  kovan: 42,
  localhost: 1337,
  avax: 43114,
};

export const INFURA_ID = 'e94bb994441c44619943b0851d6d9611';

// MAIN NETetUserNam
export const AVAX_CONTRACT = '0x0000000000000000000000000000000000000000';
export const CMB_CONTRACT = '0x7F118207db90dC67436A2552e8eF4359b2d1D972';
export const USDC_CONTRACT = '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E';
export const USDT_CONTRACT = '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7';
export const INFURA_ENDPOINT = 'https://mainnet.infura.io/v3/e94bb994441c44619943b0851d6d9611';

export const BREAKPOINTS = {
  xs: '20rem',
  sm: '30.0625rem',
  md: '48.0625rem',
  lg: '64.0625rem',
  xl: '75.0625rem',
  xxl: '90.0625rem',
};

export const COIN_TYPE = ['USDC','USDT',];

export enum ContractType {
  contractReview = 'PENDING',
  clientPayment = 0,
  boConfirm = 'PAID',
  clientConfirm = 'BO_CONFIRMED',
  paying = 1,
  boClaim = 'CONFIRMED',
  expiredContract = 'EXPIRED',
  endContract = 'CLAIMED',
}

export const CLIENT_FORM = [
  {
    label: 'Client Name',
    name: 'name',
    placeholder: 'Input placeholder',
    message: 'Client name is required',
    lg: 24,
    sm: 24,
  },
  {
    label: 'Client Address',
    name: 'address',
    placeholder: 'Input placeholder',
    message: 'Client address is required',
    lg: 24,
    sm: 24,
  },
  {
    label: 'Client Email',
    name: 'email',
    placeholder: 'Input placeholder',
    message: 'Client email is required',
    lg: 12,
    sm: 24,
  },
  {
    label: 'Client Phone',
    name: 'dial',
    placeholder: 'Input placeholder',
    message: 'Client phone is required',
    lg: 12,
    sm: 24,
  },
];

export const SERVICE_FORM = [
  {
    label: `Today's Date`,
    name: 'today_date',
    placeholder: 'mm/dd/yyyy',
    message: `Today's Date is required`,
    lg: 12,
  },
  {
    label: 'Contract End Date',
    name: 'expired_date',
    placeholder: 'mm/dd/yyyy',
    message: 'Contract End Date is required',
    lg: 12,
    tooltip:
      'This is the date that the contract becomes void and is the date that the client is able to withdraw any remaining funds from the smart contract.',
  },
  {
    label: 'Short Description of Product or Service',
    name: 'short_description',
    placeholder: 'Input placeholder',
    message: 'Short description is required',
    lg: 24,
  },
  {
    label: 'Long Description of Product or Service',
    name: 'description',
    placeholder: 'Input placeholder',
    message: 'Long description is required',
    lg: 24,
    tooltip: 'Please include a detailed list of what is provided in this agreement',
  },
];

export const NUMBER_FORM = [
  {
    label: 'Total Funds Needed',
    name: 'total_funds',
    placeholder: 'Input placeholder',
    message: 'Total funds needed is required',
    lg: 8,
    validate: new RegExp(/^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/gm),
    validateMsg: 'Only allow greater than zero!',
  },
  {
    label: 'Number of Installments',
    name: 'quantity_charge',
    placeholder: 'Input placeholder',
    message: 'Number of Installments is required',
    lg: 8,
    validate: new RegExp(/^[1-9]\d*$/),
    validateMsg: 'Only allow integers greater than zero!',
  },
  {
    label: 'Amount per Installment',
    name: 'amount_per_charge',
    placeholder: 'Input placeholder',
    message: 'Amount per Installment is required',
    lg: 8,
    validate: new RegExp(/^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/),
    validateMsg: 'Only allow greater than zero!',
  },
];
