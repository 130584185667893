import styled from 'styled-components';
import { Layout as ALayout } from 'antd';
import background from 'assets/images/homepage-background.jpg';

export const Layout = styled(ALayout)`
  //   position: relative;
  //   background: url(${background});
  //   mix-blend-mode: normal;
  //   backdrop-filter: blur(241px);
  //   overflow-y: auto;
  //   background-repeat: no-repeat;
  //   background-attachment: fixed;
  //   background-position: center;
  //   background-size: cover;

  .ant-notification {
    color: #fff;
  }
`;
