import { FC } from 'react';
import { BgMask, ContentLayout } from './content.style';
import mask from 'assets/images/bg-frame.png';
import ContentProps from './content.props';

const Content: FC<ContentProps> = ({ children }) => {
  return (
    <ContentLayout>
      {children}
      <BgMask src={mask} alt="" />
    </ContentLayout>
  );
};

export default Content;
