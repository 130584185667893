import { FC, useEffect, useState } from 'react';
import {
  ActionBtnWrap,
  AmountWrapper,
  AmountLabel,
  AmountInput,
  ButtonOutline,
  ButtonPrimary,
  Checkbox,
  ConfirmWrapper,
  ContractContent,
  ContractWrapper,
  HighLight,
  ReturnSelect,
  ReturnWrapper,
} from './contract.style';
import { Divider, Empty, Select } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { exportContract, getContractById } from 'redux/apis/contract';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { clearContract, indicateUpdateContract, setContract } from 'app/slices';
import { useQuery } from 'hooks/useQuery';
import {
  handleCancelContract,
  handleClaimPayment,
  handleConfirmProvideServices,
  handleConfirmToRelease,
  handleEscrowMoney,
  handleRequestPayment,
  handleReturnMoney,
  handleWithdrawContract,
  ownerWithdrawAfterExpired,
} from 'redux/contract/cmb';
import { useWallet } from 'hooks';
import { Notification } from 'components/Notification';
import { ContractProps } from './contract.props';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckCircle } from 'assets/images/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { routesEnum } from 'pages/Router';
import { ContractType } from 'utils/constants';
import { Loading } from 'components';

const ContractDetail: FC<ContractProps> = ({ showMenu = false, currentContract, setCurrentContract, type }) => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { account, connector } = useWallet();
  const { contract } = useAppSelector((state) => state.contract);
  const { business } = useAppSelector((state) => state.business);
  const { isAdmin } = useAppSelector((state) => state.auth);
  // const [amount, setAmount] = useState('');
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const id = query.get('id') || currentContract?.id;
  const contractDetail = contract ?? currentContract;
  const [returnSide, setReturnSide] = useState('bo');
  const finishTreatments =
    contractDetail?.treatments?.filter((item: any) => item.status === 3 || item.status === 4) || [];

  const treatmentsCount = contractDetail?.treatments?.length;
  const addressWallet = contractDetail?.user?.wallet_address || business?.walletAddress;
  // const handleChangeAmount = (value: string) => {
  //   setAmount(value);
  // };

  useEffect(() => {
    (async () => {
      if (id && !contractDetail) {
        try {
          setDataLoading(true);
          const response = await getContractById(id);
          if (response.status === 200) {
            dispatch(setContract({ ...response.data }));
          }
        } catch (error) {
          console.error(error);
        } finally {
          setDataLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (dataLoading) return <Loading />;

  if (!contractDetail)
    return (
      <ContractWrapper showMenu={showMenu}>
        <Empty description="No Result Found" />
      </ContractWrapper>
    );

  // BO create contract
  const handleExecutiveContract = async () => {
    try {
      setLoading(true);
      const encryptedData: any = location.state;
      const paymentRes = await handleRequestPayment(
        account,
        connector,
        contractDetail.total_funds,
        `${encryptedData?.iv}:${encryptedData?.data}`,
        contractDetail.wallet_address,
        contractDetail.quantity_charge,
        Math.floor(new Date(contractDetail.today_date).getTime() / 1000),
        Math.floor(new Date(contractDetail.expired_date).getTime() / 1000),
        // Math.floor(new Date().getTime() / 1000),
        // Math.floor(new Date().getTime() / 1000 + 240),
        contractDetail.coin_type
      );

      if (paymentRes) {
        dispatch(clearContract());
        Notification('success', 'The contract has been successfully created', '');
        history.push(`${routesEnum.home}`);
      }
    } catch (error: any) {
      setLoading(false);
      let errAndReason;

      if (error?.code === 4001) {
        errAndReason = error?.message.split(':');
        errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      } else if (error.message.includes('JSON-RPC')) {
        errAndReason = JSON.parse(error?.message.toString().split('.')[1])?.message.split(':');
        errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      } else {
        Notification('error', '', error.message);
      }
    }
  };

  // BO claim payment
  const claimPayment = async () => {
    const currentTreatment = contractDetail?.treatments.find((item: any) => item.status === 2);

    if (addressWallet !== account) {
      Notification('error', 'Please use the correct wallet to continue', '');
      return;
    }

    if (!id) return;

    if (!contractDetail) {
      Notification('error', 'Contract not found', '');
      return;
    }

    try {
      setLoading(true);
      await handleClaimPayment(
        account,
        connector,
        contractDetail?.payment_id as string,
        currentTreatment?.treatment_id
      );
      Notification('success', 'The payment has been successfully claimed!', '');
      history.push(`${routesEnum.home}`);
    } catch (error: any) {
      let errAndReason;

      if (error?.code === 4001) {
        errAndReason = error?.message.split(':');
        errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      } else if (error.message.includes('JSON-RPC')) {
        errAndReason = JSON.parse(error?.message.toString().split('.')[1])?.message.split(':');
        errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      } else {
        Notification('error', '', error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  // Client Cancel contract
  const handleCancel = async () => {
    // if (contractDetail.wallet_address !== account) {
    //   Notification('error', 'Please use the correct wallet to continue', '');
    //   return;
    // }

    if (!id) return;

    if (!contractDetail) {
      Notification('error', 'Contract not found', '');
      return;
    }

    try {
      setLoading(true);
      await handleCancelContract(account, connector, contractDetail.payment_id as string);
      Notification('success', 'The contract has been cancelled!', '');
      history.replace(routesEnum.home);
    } catch (error: any) {
      setLoading(false);
      let errAndReason;

      if (error?.code === 4001) {
        errAndReason = error?.message.split(':');
        errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      } else if (error.message.includes('JSON-RPC')) {
        errAndReason = JSON.parse(error?.message.toString().split('.')[1])?.message.split(':');
        errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      } else {
        Notification('error', '', error.message);
      }
    }
  };

  // Client Withdraw
  const handleWithdraw = async () => {
    if (contractDetail.wallet_address !== account) {
      Notification('error', 'Please use the correct wallet to continue', '');
      return;
    }

    if (!id) return;

    if (!contractDetail) {
      Notification('error', 'Contract not found', '');
      return;
    }

    try {
      setLoading(true);
      await handleWithdrawContract(account, connector, contractDetail.payment_id as string);
      Notification('success', 'The withdrawl has been completed', '');
      history.replace(routesEnum.home);
    } catch (error: any) {
      if (error?.reason) Notification('error', '', error.reason);
      else Notification('error', '', 'Something wrong');
      setLoading(false);
      // let errAndReason;

      // if (error?.code === 4001) {
      //   errAndReason = error?.message.split(':');
      //   errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      // } else if (error.message.includes('JSON-RPC')) {
      //   errAndReason = JSON.parse(error?.message.toString().split('.')[1])?.message.split(':');
      //   errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      // } else {
      //   Notification('error', '', error.message);
      // }
    }
  };

  const handleExportCSV = async () => {
    if (contractDetail.id) {
      try {
        const response = await exportContract([contractDetail.id]);
        if (response.status === 201) {
          const blob = new Blob([response.data as string], { type: 'data:text/csv;charset=utf-8,' });
          const blobURL = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.download = `${contractDetail.id}.csv`;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(':');
          anchor.click();
          Notification('success', 'CSV file has been successfully exported', '');
        } else {
          Notification('error', 'Something wrong', '');
        }
      } catch (error: any) {
        let errAndReason;
        if (error?.code === 4001) {
          errAndReason = error?.message.split(':');
          errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
        } else if (error.message.includes('JSON-RPC')) {
          errAndReason = JSON.parse(error?.message.toString().split('.')[1])?.message.split(':');
          errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
        } else {
          Notification('error', '', error.message);
        }
      }
    }
  };

  // Client Escrow Money
  const escrowMoney = async () => {
    // different wallet
    console.log({ account, a: contractDetail.wallet_address, b: account === contractDetail.wallet_address });
    if (contractDetail.wallet_address !== account) {
      Notification('error', 'Please use the correct wallet to continue', '');
      return;
    }

    if (!id) return;

    if (!contractDetail) {
      Notification('error', 'Contract not found', '');
      return;
    }

    try {
      setLoading(true);
      if (
        // total_funds && !/^\s*$/.test(total_funds) &&
        !isNaN(+total_funds)
      ) {
        await handleEscrowMoney(
          account,
          connector,
          contractDetail.payment_id as string,
          +total_funds,
          contractDetail.coin_type
        );
        Notification('success', 'The funds have been successfully escrowed', '');
        history.replace(routesEnum.home);
      } else {
        Notification('error', '', 'Invalid amount');
        setLoading(false);
      }
    } catch (error: any) {
      let errAndReason;

      if (error?.code === 4001) {
        errAndReason = error?.message.split(':');
        errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      } else if (error.message.includes('JSON-RPC')) {
        errAndReason = JSON.parse(error?.message.toString().split('.')[1])?.message.split(':');
        errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      } else {
        Notification('error', '', error.message);
      }
      // setAmount('');
      setLoading(false);
    }
  };

  const handleChecked = (event: CheckboxChangeEvent) => {
    setIsChecked(event.target.checked);
  };

  // Client Confirm and Release Money
  const confirmAndReleaseMoney = async () => {
    // different wallet
    const currentTreatment = contractDetail?.treatments.find((item: any) => item.status === 1);
    if (!currentTreatment) {
      Notification('error', `There's no service to confirm`, '');
      return;
    }

    if (contractDetail.wallet_address !== account) {
      Notification('error', 'Please use the correct wallet to continue', '');
      return;
    }

    if (!id) return;

    try {
      setLoading(true);
      await handleConfirmToRelease(
        account,
        connector,
        contractDetail?.payment_id as string,
        currentTreatment?.treatment_id
      );
      Notification('success', 'The money has been successfully released', '');
      history.replace(routesEnum.home);
    } catch (error: any) {
      let errAndReason;

      if (error?.code === 4001) {
        errAndReason = error?.message.split(':');
        errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      } else if (error.message.includes('JSON-RPC')) {
        errAndReason = JSON.parse(error?.message.toString().split('.')[1])?.message.split(':');
        errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      } else {
        Notification('error', '', error.message);
      }
      setLoading(false);
    }
  };

  // BO Confirm Contract
  const handleConfirmContract = async () => {
    const currentTreatment = contractDetail?.treatments.find((item: any) => item.status === 'CREATED');

    if (addressWallet !== account) {
      Notification('error', 'Please use the correct wallet to continue', '');
      return;
    }

    if (!id) return;

    try {
      setLoading(true);
      await handleConfirmProvideServices(
        account,
        connector,
        contractDetail?.payment_id as string,
        currentTreatment?.treatment_id
      );
      Notification('success', 'Service has been successfully confirmed', '');
      history.replace(routesEnum.home);
    } catch (error: any) {
      let errAndReason;
      if (error?.message?.includes('Client has'))
        Notification('error', '', 'Waiting for your client to approve your last payment request.');
      else if (error?.code === 4001) {
        errAndReason = error?.message.split(':');
        errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      } else if (error.message.includes('JSON-RPC')) {
        errAndReason = JSON.parse(error?.message.toString().split('.')[1])?.message.split(':');
        errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      } else {
        Notification('error', '', error.message);
      }
      setLoading(false);
    }
  };

  // CMB decide to return money
  const returnMoney = async () => {
    if (!id) return;

    if (!contractDetail) {
      Notification('error', 'Contract not found', '');
      return;
    }

    try {
      setLoading(true);
      const receiver = returnSide === 'bo' ? contractDetail.user?.wallet_address : contractDetail.wallet_address;

      await handleReturnMoney(account, connector, contractDetail?.payment_id as string, receiver as string);
      Notification('success', 'Return money successfully!', '');
      history.push(`${routesEnum.home}`);
    } catch (error: any) {
      setLoading(false);
      let errAndReason;

      if (error?.code === 4001) {
        errAndReason = error?.message.split(':');
        errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      } else if (error.message.includes('JSON-RPC')) {
        errAndReason = JSON.parse(error?.message.toString().split('.')[1])?.message.split(':');
        errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      } else {
        Notification('error', '', error.message);
      }
    }
  };

  const ownerClaim = async () => {
    if (!id) return;

    if (!contractDetail) {
      Notification('error', 'Contract not found', '');
      return;
    }

    try {
      setLoading(true);

      await ownerWithdrawAfterExpired(account, connector, contractDetail.payment_id as string);
      Notification('success', 'Claim money successfully!', '');
      history.push(`${routesEnum.home}`);
    } catch (error: any) {
      console.log('🚀 ~ file: index.tsx ~ line 462 ~ ownerClaim ~ error', error);
      setLoading(false);
      let errAndReason;
      if (error?.message.includes('50 blocks')) Notification('success', 'Claim money successfully!', '');
      else if (error?.code === 4001) {
        errAndReason = error?.message.split(':');
        errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      } else if (error.message.includes('JSON-RPC')) {
        errAndReason = JSON.parse(error?.message.toString().split('.')[1])?.message.split(':');
        errAndReason && Notification('error', errAndReason[1], errAndReason[0]);
      } else {
        Notification('error', '', error.message);
      }
    }
  };

  const renderBottomPart = (status: any) => {
    switch (status) {
      case ContractType.contractReview:
        return (
          <>
            <Checkbox onChange={handleChecked}>
              I have reviewed the information contained in this contract as well as the{' '}
              <HighLight href="https://cryptomybiz.com/disclosure-agreement" target="_blank" rel="noopen noreferrer">
                CMB Disclosure agreement
              </HighLight>{' '}
              and agree to all terms and conditions and 1.5% transaction fee for the execution of the contract.
            </Checkbox>
            <ActionBtnWrap>
              <ButtonOutline disabled={!!contract} icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv file
              </ButtonOutline>
              <ButtonPrimary loading={loading} disabled={!isChecked || loading} onClick={handleExecutiveContract}>
                Agree and Execute the Contract
              </ButtonPrimary>
              <ButtonPrimary
                disabled={loading}
                onClick={() => {
                  dispatch(indicateUpdateContract());
                  history.push(routesEnum.createContract);
                }}
              >
                Update Contract
              </ButtonPrimary>
            </ActionBtnWrap>
          </>
        );

      case ContractType.clientPayment:
        if (location.pathname === '/clientPayment')
          return (
            <ActionBtnWrap>
              <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv file
              </ButtonOutline>
              {/* {!business && ( */}
              <ButtonPrimary loading={loading} onClick={escrowMoney}>
                Escrow Money
              </ButtonPrimary>
              {/* )} */}
            </ActionBtnWrap>
          );

        return (
          <>
            {contractDetail.statusString && (
              <ConfirmWrapper>
                <CheckCircle />
                <ContractContent>{contractDetail.statusString}</ContractContent>
              </ConfirmWrapper>
            )}

            <ActionBtnWrap>
              <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv file
              </ButtonOutline>
            </ActionBtnWrap>
          </>
        );

      // case ContractType.paying:
      //   if (isAdmin) {
      //     return (
      //       <ActionBtnWrap>
      //         <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
      //           Download .csv file
      //         </ButtonOutline>
      //         <ButtonPrimary loading={loading} onClick={ownerClaim}>
      //           CMB Obtain
      //         </ButtonPrimary>
      //       </ActionBtnWrap>
      //     );
      //   }

      //   return (
      //     <ActionBtnWrap>
      //       <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
      //         Download .csv file
      //       </ButtonOutline>
      //       {!business && (
      //         <ButtonPrimary loading={loading} onClick={escrowMoney}>
      //           Escrow Money
      //         </ButtonPrimary>
      //       )}
      //     </ActionBtnWrap>
      //   );

      case 1:
        //  ContractType.clientConfirm:
        if (contractDetail.is_expired && isAdmin)
          return (
            <>
              <ConfirmWrapper>
                <CheckCircle />
                <ContractContent>Contract Expired. BO Confirmed</ContractContent>
              </ConfirmWrapper>
              <ActionBtnWrap>
                <ReturnWrapper>
                  <ReturnSelect value={returnSide} onChange={(val) => setReturnSide(val as string)}>
                    <Select.Option value="bo">BO</Select.Option>
                    <Select.Option value="client">Client</Select.Option>
                  </ReturnSelect>
                </ReturnWrapper>
                <ButtonPrimary loading={loading} onClick={returnMoney}>
                  Return money
                </ButtonPrimary>
              </ActionBtnWrap>
            </>
          );
        else if (location.pathname === '/client/cancel') {
          return (
            <ActionBtnWrap>
              <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv file
              </ButtonOutline>
              <ButtonPrimary loading={loading} onClick={handleCancel}>
                Cancel
              </ButtonPrimary>
            </ActionBtnWrap>
          );
        }
        //  if (location.pathname === '/bo/confirm')
        else {
          return (
            <>
              {contractDetail.statusString && (
                <ConfirmWrapper>
                  <CheckCircle />
                  <ContractContent>{contractDetail.statusString}</ContractContent>
                </ConfirmWrapper>
              )}
              <ActionBtnWrap>
                <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                  Download .csv file
                </ButtonOutline>
                <ButtonPrimary loading={loading} onClick={handleConfirmContract}>
                  Confirm finish service{' '}
                  {finishTreatments.length + 1 > treatmentsCount ? treatmentsCount : finishTreatments.length + 1}/
                  {treatmentsCount}
                </ButtonPrimary>
              </ActionBtnWrap>
            </>
          );
        }

      case 2:
        if (location.pathname === '/client/confirm')
          return (
            <>
              <ConfirmWrapper>
                <CheckCircle />
                <ContractContent>Funded</ContractContent>
              </ConfirmWrapper>
              <ActionBtnWrap>
                <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                  Download .csv file
                </ButtonOutline>
                <ButtonPrimary loading={loading} onClick={confirmAndReleaseMoney}>
                  Confirm and release money service {finishTreatments.length + 1}/{treatmentsCount}
                </ButtonPrimary>
              </ActionBtnWrap>
            </>
          );
        else if (location.pathname === '/bo/claim-payment')
          return (
            <>
              <ConfirmWrapper>
                <CheckCircle />
                <ContractContent>Ready to claim</ContractContent>
              </ConfirmWrapper>
              <ActionBtnWrap>
                <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                  Download .csv file
                </ButtonOutline>
                <ButtonPrimary loading={loading} onClick={claimPayment}>
                  Claim payment {finishTreatments.length + 1}/{treatmentsCount}
                </ButtonPrimary>
              </ActionBtnWrap>
            </>
          );
        else if (location.pathname === '/bo/confirm')
          return (
            <>
              <ActionBtnWrap>
                <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                  Download .csv file
                </ButtonOutline>
                <ButtonPrimary loading={loading} onClick={handleConfirmContract}>
                  Confirm finish service{' '}
                  {finishTreatments.length + 1 > treatmentsCount ? treatmentsCount : finishTreatments.length + 1}/
                  {treatmentsCount}
                </ButtonPrimary>
              </ActionBtnWrap>
            </>
          );
        return (
          <>
            {contractDetail.statusString && (
              <ConfirmWrapper>
                <CheckCircle />
                <ContractContent>{contractDetail.statusString}</ContractContent>
              </ConfirmWrapper>
            )}
            <ActionBtnWrap>
              <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv file
              </ButtonOutline>
            </ActionBtnWrap>
          </>
        );

      case 3:
        return (
          <>
            <ActionBtnWrap>
              <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv file
              </ButtonOutline>
              <ButtonPrimary disabled>Finished</ButtonPrimary>
            </ActionBtnWrap>
          </>
        );
      case 5:
        if (isAdmin && location.pathname === '/admin') {
          return (
            <ActionBtnWrap>
              <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv file
              </ButtonOutline>
              <ButtonPrimary loading={loading} onClick={ownerClaim}>
                CMB Claim
              </ButtonPrimary>
            </ActionBtnWrap>
          );
        } else if (location.pathname === '/smart-contract-review')
          return (
            <>
              {contractDetail.statusString && (
                <ConfirmWrapper>
                  <CheckCircle />
                  <ContractContent>{contractDetail.statusString}</ContractContent>
                </ConfirmWrapper>
              )}

              <ActionBtnWrap>
                <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                  Download .csv file
                </ButtonOutline>
              </ActionBtnWrap>
            </>
          );
        return (
          <>
            {contractDetail.statusString && (
              <ConfirmWrapper>
                <CheckCircle />
                <ContractContent>{contractDetail.statusString}</ContractContent>
              </ConfirmWrapper>
            )}
            <ActionBtnWrap>
              <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv file
              </ButtonOutline>
              <ButtonPrimary loading={loading} onClick={handleWithdraw}>
                Withdraw
              </ButtonPrimary>
            </ActionBtnWrap>
          </>
        );
      case ContractType.boConfirm:
        if (isAdmin) {
          return (
            <ActionBtnWrap>
              <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv file
              </ButtonOutline>
              <ButtonPrimary loading={loading} onClick={ownerClaim}>
                CMB Obtain
              </ButtonPrimary>
            </ActionBtnWrap>
          );
        }

        if (contractDetail.is_expired) {
          return (
            <>
              <ActionBtnWrap>
                <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                  Download .csv file
                </ButtonOutline>
                <ButtonPrimary disabled={!!business} loading={loading} onClick={handleWithdraw}>
                  Withdraw
                </ButtonPrimary>
              </ActionBtnWrap>
            </>
          );
        }

        return business || type === 'bo' ? (
          <>
            {contractDetail.statusString && (
              <ConfirmWrapper>
                <CheckCircle />
                <ContractContent>{contractDetail.statusString}</ContractContent>
              </ConfirmWrapper>
            )}
            <ActionBtnWrap>
              <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv file
              </ButtonOutline>
              <ButtonPrimary loading={loading} onClick={handleConfirmContract}>
                Confirm finish service{' '}
                {finishTreatments.length + 1 > treatmentsCount ? treatmentsCount : finishTreatments.length + 1}/
                {treatmentsCount}
              </ButtonPrimary>
            </ActionBtnWrap>
          </>
        ) : (
          <>
            {contractDetail.statusString && (
              <ConfirmWrapper>
                <CheckCircle />
                <ContractContent>{contractDetail.statusString}</ContractContent>
              </ConfirmWrapper>
            )}
            <ActionBtnWrap>
              <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv files
              </ButtonOutline>
              <ButtonPrimary disabled={!!business} loading={loading} onClick={handleCancel}>
                Cancel
              </ButtonPrimary>
            </ActionBtnWrap>
          </>
        );
      case 'FINISHED':
        return (
          <>
            <ConfirmWrapper>
              <CheckCircle />
              <ContractContent>Ready to claim</ContractContent>
            </ConfirmWrapper>
            <ActionBtnWrap>
              <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv file
              </ButtonOutline>
              <ButtonPrimary loading={loading} onClick={claimPayment}>
                Claim payment
              </ButtonPrimary>
            </ActionBtnWrap>
          </>
        );
      case ContractType.boClaim:
        if (isAdmin) {
          return (
            <ActionBtnWrap>
              <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv file
              </ButtonOutline>
              <ButtonPrimary loading={loading} onClick={ownerClaim}>
                CMB Obtain
              </ButtonPrimary>
            </ActionBtnWrap>
          );
        }

        return (
          <>
            <ConfirmWrapper>
              <CheckCircle />
              <ContractContent>Ready to claim</ContractContent>
            </ConfirmWrapper>
            <ActionBtnWrap>
              <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv file
              </ButtonOutline>
              <ButtonPrimary loading={loading} onClick={claimPayment}>
                Claim payment
              </ButtonPrimary>
            </ActionBtnWrap>
          </>
        );

      case 9:
        return (
          <>
            <ActionBtnWrap>
              <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv file
              </ButtonOutline>
              <ButtonPrimary disabled>Claimed by CMB</ButtonPrimary>
            </ActionBtnWrap>
          </>
        );

      case 10:
        return (
          <>
            <ActionBtnWrap>
              <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv file
              </ButtonOutline>
              <ButtonPrimary disabled>Claimed by Client</ButtonPrimary>
            </ActionBtnWrap>
          </>
        );

      case ContractType.expiredContract:
        return (
          <ActionBtnWrap>
            <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
              Download .csv file
            </ButtonOutline>
            {!business && (
              <ButtonPrimary disabled={!!business} loading={loading} onClick={handleWithdraw}>
                Withdraw
              </ButtonPrimary>
            )}
          </ActionBtnWrap>
        );

      default:
        return (
          <>
            {contractDetail.statusString && (
              <ConfirmWrapper>
                <CheckCircle />
                <ContractContent>{contractDetail.statusString}</ContractContent>
              </ConfirmWrapper>
            )}

            <ActionBtnWrap>
              <ButtonOutline icon={<DownloadOutlined />} onClick={handleExportCSV}>
                Download .csv file
              </ButtonOutline>
            </ActionBtnWrap>
          </>
        );
    }
  };

  const parseTime = (time: string) => new Date(time).toLocaleString();

  const {
    today_date,
    name,
    total_funds,
    // amount_per_charge,
    // quantity_charge,
    expired_date,
    updated_at,
    status,
    user,
    short_description,
    coin_type,
    payment_id,
    quantity_charge,
    amount_per_charge,
    description,
  } = contractDetail;

  const businessName = contractDetail?.bo?.name || user?.name;
  // user?.name || business?.businessName;
  const balance = (treatmentsCount - finishTreatments.length) * amount_per_charge || 0;

  // const shouldShowPaidAmount = status === ContractType.clientPayment && !isAdmin;
  console.log({ contractDetail, description });

  const renderContent = (status: any) => {
    if (location.pathname === '/smart-contract-review')
      return (
        <ContractContent>
          This Smart Contract #{payment_id}, created on {parseTime(today_date)}, between the business known as{' '}
          {businessName}, and the client, known as {name}, is for the product(s)/service(s) known as {short_description}
          .
          <br /> <br />
          {status !== 3 && status !== 4 && status !== 10 && (
            <>
              {' '}
              Total funds needed are {total_funds} {coin_type}, which will be paid to {businessName} over{' '}
              {quantity_charge} installments in exchange for the completion of the contract as described below: <br />{' '}
              <br />
            </>
          )}
          Contract Description: <br /> <br /> {description}.
          {/* {shouldShowPaidAmount && `You have paid ${paid_amount} ${coin_type}.`} */}
          {status !== 3 && status !== 4 && status !== 10 && (
            <>
              <br /> <br />
              {status !== 0 ? (
                <>
                  Funds for this contract have been provided by {name}. This contract is awaiting the Business Owner to
                  provide product(s) or service(s). <br /> <br />
                </>
              ) : (
                <>
                  {' '}
                  Funds for this service are to be provided by {name}, to the blockchain before work begins or products
                  are delivered.
                  <br /> <br />
                </>
              )}
              This contract may be cancelled by either party within three (3) days and all funds will be available to
              withdraw. Any unused funds will be available for withdraw after thirty (30) days after{' '}
              {parseTime(expired_date)}.
            </>
          )}
          {(status === 3 || status === 4 || status === 10) && (
            <>
              <br /> <br />
              This contract was completed on {parseTime(updated_at)}. No further transactions are allowed on this
              contract.
              <br></br> <br></br>
              Thank you for using Crypto My Biz
              <br></br>
              <a href="http://cryptomybiz.com" target="blank" referrerPolicy="no-referrer">
                cryptomybiz.com
              </a>
              <br></br>
              <a href="mailto:imready@cryptomybiz.io" target="blank" referrerPolicy="no-referrer">
                imready@cryptomybiz.io
              </a>
            </>
          )}
        </ContractContent>
      );
    if (location.pathname === '/client/cancel')
      return (
        <ContractContent>
          This Smart Contract #{payment_id}, created on {parseTime(today_date)}, between the business known as{' '}
          {businessName}, and the client, known as {name}, is for the product(s)/service(s) known as {short_description}
          .
          <br /> <br />I have decided to cancel this contract in its entirety and I am requesting that all funds
          remaining in the escrow account be released to the client.
        </ContractContent>
      );
    switch (status) {
      case ContractType.clientPayment:
        if (location.pathname === '/client/cancel')
          return (
            <ContractContent>
              This Smart Contract #{payment_id}, created on {parseTime(today_date)}, between the business known as{' '}
              {businessName}, and the client, known as {name}, is for the product(s)/service(s) known as{' '}
              {short_description}.
              <br /> <br />I have decided to cancel this contract in its entirety and I am requesting that all funds
              remaining in the escrow account be released to the client.
            </ContractContent>
          );
        return (
          <ContractContent>
            This Contract #{payment_id} created on {parseTime(today_date)}, between {businessName}, and the client{' '}
            {name} is for the product(s)/service(s) known as {short_description} at a cost of {total_funds} {coin_type},
            to be paid to in {quantity_charge} installments of {amount_per_charge} {coin_type}.
            <br /> <br />
            Contract Description: <br /> <br /> {description}.
            <br /> <br />
            Funds for this service are to be provided by {name}, before product(s)/services(s) are delivered.
            <br /> <br />
            The total funds required for the contract are {balance} {coin_type}. After the contract is funded, any
            unused funds will be made available for withdrawal for 30 days from the contract end date. The contract is
            scheduled to reach its end date on {parseTime(expired_date)}. After this period, any funds requested are
            subject to a 25% retrieval fee.
          </ContractContent>
        );
      case 1: {
        if (location.pathname === '/client/cancel')
          return (
            <ContractContent>
              This Smart Contract #{payment_id}, created on {parseTime(today_date)}, between the business known as{' '}
              {businessName}, and the client, known as {name}, is for the product(s)/service(s) known as{' '}
              {short_description}.
              <br /> <br />I have decided to cancel this contract in its entirety and I am requesting that all funds
              remaining in the escrow account be released to the client.
            </ContractContent>
          );

        return (
          <ContractContent>
            This Smart Contract #{payment_id}, created on {parseTime(today_date)}, between the business known as{' '}
            {businessName}, and the client, known as {name}, is for the product(s)/service(s) known as{' '}
            {short_description}.
            <br /> <br />I hereby agree that I have provided the product(s)/service(s) mentioned herein, and am
            requesting that the funds for this installment of the contract be released.
          </ContractContent>
        );
      }

      case 2:
        if (location.pathname === '/client/confirm')
          return (
            <ContractContent>
              This Smart Contract #{payment_id}, created on {parseTime(today_date)}, between the business known as{' '}
              {businessName}, and the client, known as {name}, is for the product(s)/service(s) known as{' '}
              {short_description}.
              <br /> <br />I hereby agree that I have received the product(s)/service(s) mentioned herein, and I approve
              the contract to release payment.
            </ContractContent>
          );
        else if (location.pathname === '/bo/claim-payment')
          return (
            <ContractContent>
              This Smart Contract #{payment_id}, created on {parseTime(today_date)}, between the business known as{' '}
              {businessName}, and the client, known as {name}, is for the product(s)/service(s) known as{' '}
              {short_description}.
              <br /> <br />
            </ContractContent>
          );
        else if (location.pathname === '/bo/confirm')
          return (
            <ContractContent>
              This Smart Contract #{payment_id}, created on {parseTime(today_date)}, between the business known as{' '}
              {businessName}, and the client, known as {name}, is for the product(s)/service(s) known as{' '}
              {short_description}.
              <br /> <br />
              Total funds needed are {total_funds} {coin_type}, which will be paid to {businessName} over{' '}
              {quantity_charge} installments in exchange for the completion of the contract as described below:
              <br /> <br /> Contract Description: <br /> <br /> {description}.
              {/* {shouldShowPaidAmount && `You have paid ${paid_amount} ${coin_type}.`} */}
              <br /> <br />I hereby agree that I have provided the product(s)/service(s) mentioned herein, and am
              requesting that the funds for this installment of the contract be released.
            </ContractContent>
          );
        else if (location.pathname === '/client/cancel') {
          return (
            <ContractContent>
              This Smart Contract #{payment_id}, created on {parseTime(today_date)}, between the business known as{' '}
              {businessName}, and the client, known as {name}, is for the product(s)/service(s) known as{' '}
              {short_description}.
              <br /> <br />I have decided to cancel this contract in its entirety and I am requesting that all funds
              remaining in the escrow account be released to the client.
            </ContractContent>
          );
        }

        return (
          <ContractContent>
            This Smart Contract #{payment_id}, created on {parseTime(today_date)}, between the business known as{' '}
            {businessName}, and the client, known as {name}, is for the product(s)/service(s) known as{' '}
            {short_description}.
            <br /> <br />
            Total funds needed are {total_funds} {coin_type}, which will be paid to {businessName} over{' '}
            {quantity_charge} installments in exchange for the completion of the contract as described below:
            {/* {shouldShowPaidAmount && `You have paid ${paid_amount} ${coin_type}.`} */}
            <br /> <br />
            Contract Description: <br /> <br /> {description}.
            <br /> <br />
            Funds for this service are to be provided by {name}, to the blockchain before work begins or products are
            delivered.
            <br /> <br />
            This contract may be cancelled by either party within three (3) days and all funds will be available to
            withdraw. Any unused funds will be available for withdraw after thirty (30) days after{' '}
            {parseTime(expired_date)}.
          </ContractContent>
        );

      case 5:
        if (location.pathname !== '/admin')
          return (
            <ContractContent>
              This Smart Contract #{payment_id}, created on {parseTime(today_date)}, between the business known as{' '}
              {businessName}, and the client, known as {name}, is for the product(s)/service(s) known as{' '}
              {short_description}.
              <br /> <br />A total of {total_funds} {coin_type} was escrowed by {name} on {parseTime(today_date)}.
              <br /> <br />
              {businessName} has been paid 0 {/* {total_funds - balance}  */}
              {coin_type} for product(s)/service(s) delivered.
              <br /> <br />
              The remaining funds in the escrow account, which total {total_funds} {coin_type}, are available for
              withdrawal for 30 days from the contract end date, which is {parseTime(expired_date)}. After this
              period, any funds requested are subject to a 25% retrieval fee.
            </ContractContent>
          );
        else
          return (
            <ContractContent>
              This Smart Contract #{payment_id}, created on {parseTime(today_date)}, between the business known as{' '}
              {businessName}, and the client, known as {name}, is for the product(s)/service(s) known as{' '}
              {short_description}.
              <br /> <br />A total of {total_funds} {coin_type} was escrowed by {name} on {parseTime(today_date)}. The
              remaining funds in the escrow account are available for CMB to claim.{' '}
            </ContractContent>
          );

      default:
        return (
          <ContractContent>
            This Smart Contract #{payment_id}, created on {parseTime(today_date)}, between the business known as{' '}
            {user?.name || business?.businessName}, and the client, known as {name}, is for the product(s)/service(s)
            known as {short_description}.
            <br /> <br />
            Total funds needed are {total_funds} {coin_type}, which will be paid to {businessName} over{' '}
            {quantity_charge} installments in exchange for the completion of the contract as described below:
            {/* {shouldShowPaidAmount && `You have paid ${paid_amount} ${coin_type}.`} */}
            <br /> <br />
            Contract Description: <br /> <br /> {description}.
            <br /> <br />
            Funds for this service are to be provided by {name}, to the blockchain before work begins or products are
            delivered.
            <br /> <br />
            This contract may be cancelled by either party within three (3) days and all funds will be available to
            withdraw. Any unused funds will be available for withdraw after thirty (30) days after{' '}
            {parseTime(expired_date)}.
          </ContractContent>
        );
    }
  };

  return (
    <>
      {status === ContractType.clientPayment && !business && (
        <AmountWrapper>
          <AmountLabel>payment amount ({coin_type})</AmountLabel>
          <AmountInput placeholder="Enter your amount" type="text" value={total_funds} disabled />
        </AmountWrapper>
      )}
      <ContractWrapper showMenu={showMenu}>
        {Object.keys(contractDetail).length !== 0 ? (
          <>
            <div>
              {renderContent(status)}
              <Divider type="horizontal" />
            </div>
            {renderBottomPart(status)}
          </>
        ) : (
          <ContractContent>
            This contract does not exist. You could be using the wrong URL. Please check on your email for the correct
            one.
          </ContractContent>
        )}
      </ContractWrapper>
    </>
  );
};

export default ContractDetail;
