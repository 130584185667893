import styled from 'styled-components';

export const FormWrapper = styled.div`
  padding: 20px;
  background: linear-gradient(0deg, rgba(86, 86, 86, 0.25), rgba(86, 86, 86, 0.25)), rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 40px rgba(24, 35, 43, 0.1);
  backdrop-filter: blur(500px);
  @media screen and (min-width: ${({ theme }) => theme.breakPoints.sm}) {
    padding: 40px;
  }
`;
