import { Button } from 'antd';
import styled from 'styled-components';

export const FillBtn = styled(Button)`
  border: none;
  outline: none;
  background: rgba(111, 191, 236, 0.3);
  backdrop-filter: blur(24px);
  color: #fff;

  &:hover,
  &:focus {
    background: rgba(111, 191, 236, 0.3);
    color: #fff;
  }
`;
