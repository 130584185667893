import { notification } from 'antd';
import { NOTIFICATION_TYPE } from 'types/noti-types';

export const Notification = (type: NOTIFICATION_TYPE, description: any, message: string, duration = 4.5) => {
  return notification[type]({
    message,
    description,
    duration,
    getContainer: () => document.getElementById('main-layout') as HTMLElement,
  });
};
