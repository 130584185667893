import { Divider } from 'antd';
import { FC } from 'react';
import { FooterLayout, Socials } from './footer.style';
import { TwitterOutlined, LinkedinOutlined, YoutubeOutlined } from '@ant-design/icons';

const Footer: FC = () => {
  return (
    <FooterLayout>
      Copyright © 2022 - CryptoMyBiz - All Rights Reserved.
      <br />
      <a href="mailto:imready@cryptomybiz.io" target="_blank" rel="noreferrer">
        imready@cryptomybiz.io
      </a>
      <Divider type="vertical" />
      <a href="https://cryptomybiz.com/privacy-policy-1" target="_blank" rel="noreferrer">
      Privacy Policy
      </a>
 {" "}
      <a href="https://cryptomybiz.com/disclosure-agreement" target="_blank" rel="noreferrer">
       | Disclosure Agreement
      </a>
      <Socials>
        <a href="https://twitter.com/CryptoMyBiz" target="_blank" rel="noreferrer">
          <TwitterOutlined />
        </a>
        <a href="https://www.linkedin.com/company/crypto-my-biz/" target="_blank" rel="noreferrer">
          <LinkedinOutlined />
        </a>
        <a href="https://www.youtube.com/channel/UCejiwJ-RRr0KiMCM7ddA6Vw" target="_blank" rel="noreferrer">
          <YoutubeOutlined />
        </a>
      </Socials>
    </FooterLayout>
  );
};

export default Footer;
